import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import NeoBrutalistModal from "./NeoBrutalistModal";
import NeoBrutalistButton from "./NeoBrutalistButton";
import NeoBrutalistInput from "./NeoBrutalistInput";
import NeoBrutalistTextArea from "./NeoBrutalistTextArea";
import NeoBrutalistSelect from "./NeoBrutalistSelect";
import NeoBrutalistNotification from "./NeoBrutalistNotification";

import { FaThumbsUp, FaThumbsDown, FaWizardsOfTheCoast, FaCoins } from "react-icons/fa";
import { PaperAirplaneIcon, ClipboardIcon } from "@heroicons/react/solid";
import { DownloadIcon } from "@heroicons/react/solid"; // Importer l'icône de téléchargement
import Loader from "./Loader";
import logger from "../utils/logger";

const ExerciseCorrection = ({
  isOpen,
  onClose,
  exercise,
  onAllCorrected,
  onAutoCorrected = () => {},
}) => {
  const { t } = useTranslation();
  const [exerciseReplies, setExerciseReplies] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [corrections, setCorrections] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [overallFeedback, setOverallFeedback] = useState("");
  const [settings, setSettings] = useState({});
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [error, setError] = useState("");
  const [correctedStudents, setCorrectedStudents] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isAutoCorrectingLoading, setIsAutoCorrectingLoading] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    logger.log("settings", settings);
  }, [settings]);

  useEffect(() => {
    logger.log("correctedStudents", correctedStudents);
  }, [correctedStudents]);

  useEffect(() => {
    logger.log("selected student", selectedStudent);
  }, [selectedStudent]);

  useEffect(() => {
    logger.log("corrections", corrections);
  }, [corrections]);

  useEffect(() => {
    if (isOpen && exercise) {
      fetchStudentExercises();
      setSelectedStudent(null); 
    }
  }, [isOpen, exercise]);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/settings/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setSettings(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des paramètres:", error);
    }
  };

  const fetchStudentExercises = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/exercise-reply/from-exercise/${exercise._id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setExerciseReplies(response.data);
      initializeCorrections(response.data);
      updateCorrectedStudents(response.data);
    } catch (error) {
      logger.error(
        "Erreur lors de la récupération des exercices des étudiants:",
        error
      );
    }
  };
  const updateCorrectedStudents = (replies) => {
    const correctedStudIds = [];
    replies.forEach((reply) => {
      if (reply.correctedAt) {
        correctedStudIds.push(reply._id);
      }
    });
    setCorrectedStudents(correctedStudIds);
  };

  const initializeCorrections = (exercises) => {
    const initialCorrections = {};
    exercises.forEach((exercise) => {
      if (Array.isArray(exercise.answers)) {
        initialCorrections[exercise._id] = exercise.answers.map((answer) => ({
          score:
            typeof answer.correction?.score !== "undefined"
              ? answer.correction.score
              : "",
          feedback: answer.correction?.feedback || "",
        }));
      } else {
        initialCorrections[exercise._id] = [];
      }
    });
    setCorrections(initialCorrections);
  };

  const handleStudentSelect = (studentId) => {
    const student = exerciseReplies.find((se) => se._id === studentId);
    setSelectedStudent(student);

    setOverallFeedback(student.overallFeedback ? student.overallFeedback : "");
  };

  const handleCorrectionChange = (questionIndex, field, value) => {
    setCorrections((prevCorrections) => ({
      ...prevCorrections,
      [selectedStudent._id]: prevCorrections[selectedStudent._id].map(
        (correction, index) =>
          index === questionIndex
            ? { ...correction, [field]: value }
            : correction
      ),
    }));
  };

  const calculateTotalScore = () => {
    const studentCorrections = corrections[selectedStudent._id];
    try {
      const totalScore = studentCorrections.reduce((acc, correction) => {
        const score = parseFloat(correction.score) || 0;
        return acc + score;
      }, 0);
      const averageScore =
        studentCorrections.length > 0
          ? totalScore / studentCorrections.length
          : 0;
      return averageScore;
    } catch (error) {
      logger.error("Erreur lors du calcul du score total:", error);
      return 0;
    }
  };

  useEffect(() => {
    if (selectedStudent) {
      logger.log("**** calculateTotalScore", calculateTotalScore());
      setTotalScore(calculateTotalScore());
    }
  }, [corrections, selectedStudent]);

  const handleSubmitCorrection = async () => {
    const unansweredQuestions = corrections[selectedStudent._id].findIndex(
      (correction) => correction.score === ""
    );

    if (unansweredQuestions !== -1) {
      setError(t("Please grade all questions before submitting"));
      setOpenQuestionIndex(unansweredQuestions);
      return;
    }

    try {
      const rounded_total = Math.round(totalScore * 2) / 2;

      await axios.post(
        `${process.env.REACT_APP_API_URL}/exercise-reply/${selectedStudent._id}/correct`,
        {
          corrections: corrections[selectedStudent._id],
          overallScore: rounded_total,
          overallFeedback: overallFeedback,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setCorrectedStudents((prev) => [...prev, selectedStudent._id]);

      const nextUncorrectedStudent = exerciseReplies.find(
        (reply) =>
          !correctedStudents.includes(reply._id) &&
          reply._id !== selectedStudent._id
      );

      if (nextUncorrectedStudent) {
        handleStudentSelect(nextUncorrectedStudent._id);
      } else {
        onClose();
        onAllCorrected();
      }
    } catch (error) {
      logger.error("Erreur lors de la soumission de la correction:", error);
      setError(t("Error submitting correction. Please try again."));
    }
  };

  const toggleQuestion = (index) => {
    setOpenQuestionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleCopyLink = () => {
    const link = `${window.location.origin}/correction/${selectedStudent.uniqueLink}`;
    navigator.clipboard.writeText(link);
    setNotificationMessage(t("Correction link copied to clipboard!"));
    setShowNotification(true);
  };

  const handleDownloadCorrection = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/exercise-reply/${selectedStudent._id}/download-correction`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      logger.log("selectedStudent", selectedStudent);

      const formattedFileName = selectedStudent.exercise.title
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
      const formatedFirstName = selectedStudent.student.firstName
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
      link.download = `Correction_${
        formatedFirstName + "_" + formattedFileName
      }.pdf`;
      link.click();
    } catch (error) {
      logger.error(
        "Erreur lors du téléchargement du PDF de correction:",
        error
      );
    }
  };

  const handleAutoCorrection = async () => {
    setIsAutoCorrectingLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/exercise-reply/${selectedStudent._id}/auto-correction`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      await fetchStudentExercises();
      onAutoCorrected();
      handleStudentSelect(selectedStudent._id);      
      
    } catch (error) {
      logger.error("Erreur lors de l'auto-correction:", error);
      setError(t("Error during auto-correction. Please try again."));
    } finally {
      setIsAutoCorrectingLoading(false);
    }
  };

  return (
    <NeoBrutalistModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("Exercise Correction")}
      size="xl"
    >
      {isAutoCorrectingLoading ? (
        <div className="flex justify-center items-center h-64 mb-8">
          <Loader
            title={t("Auto-correcting")}
            description={t(
              "Please wait while the exercise is being auto-corrected"
            )}
          />
        </div>
      ) : (
        <>
          <div className="flex">
            {exerciseReplies.length > 0 && (
              <div className="w-1/4 pr-4 border-r-4 border-black">
                <h2 className="text-2xl font-bold mb-4">{t("Students")}</h2>
                <div className="space-y-2 flex flex-col">
                  {exerciseReplies.map((se) => (
                    <NeoBrutalistButton
                      key={se._id}
                      onClick={() => handleStudentSelect(se._id)}
                      style={
                        selectedStudent && selectedStudent._id === se._id
                          ? "primary"
                          : "secondary"
                      }
                      className="w-full text-left"
                    >
                      {se.student.firstName}{" "}
                      {correctedStudents.includes(se._id) ? "✓" : ""}
                    </NeoBrutalistButton>
                  ))}
                </div>
              </div>
            )}
            <div
              className={exerciseReplies.length === 0 ? "w-full" : "w-3/4 pl-4"}
            >
              {selectedStudent ? (
                selectedStudent.completed ? (
                  <div className="space-y-6">
                    <h2 className="text-2xl font-bold">
                      {t("Exercise")}: {exercise.title}
                    </h2>
                    <p className="mb-4">{exercise.description}</p>
                    {selectedStudent.answers.map((answer, index) => (
                      <div key={index} className="mb-4">
                        <div
                          className={`flex justify-between items-center p-4 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] cursor-pointer hover:bg-yellow-400 ${
                            openQuestionIndex === index
                              ? "bg-yellow-400"
                              : "bg-white"
                          }`}
                          onClick={() => toggleQuestion(index)}
                        >
                          <h3 className="text-2xl font-bold">
                            {t("Question")} {index + 1}
                          </h3>
                          <span className="text-2xl ml-8">
                            {openQuestionIndex === index ? "▲" : "▼"}
                          </span>
                        </div>
                        {openQuestionIndex === index && (
                          <div className="mt-4 p-4 bg-grey-200 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
                            <p className="mb-2">
                              <strong>{t("Question")}:</strong>{" "}
                              {exercise.questions[index].question}
                            </p>
                            {exercise.type === "multiple_choice" && (
                              <>
                                <p className="mb-2">
                                  <strong>{t("Student's Answer")}:</strong>{" "}
                                  {
                                    exercise.questions[index].choices[
                                      answer.answer
                                    ]
                                  }
                                </p>
                                <p className="mb-2">
                                  <strong>{t("Correct Answer")}:</strong>{" "}
                                  {
                                    exercise.questions[index].choices[
                                      exercise.questions[index].answer
                                    ]
                                  }
                                </p>
                              </>
                            )}
                            {exercise.type === "free_writing" && (
                              <>
                                <p className="mb-2">
                                  <strong>{t("Student's Answer")}:</strong>{" "}
                                  {answer.answer}
                                </p>
                                <p className="mb-2">
                                  <strong>{t("Correct Answer")}:</strong>{" "}
                                  {exercise.questions[index].answer}
                                </p>
                              </>
                            )}
                            {exercise.type === "matching" && (
                              <>
                                <p className="mb-2">
                                  <strong>{t("Student's Answer")}:</strong>
                                </p>
                                <hr className="border-black border-2 mb-2" />

                                <div className="mb-2">
                                  {exercise.questions[
                                    index
                                  ].matchingSet.leftColumn.map(
                                    (match, matchIndex) => (
                                      <p key={matchIndex}>
                                        <strong>
                                          {
                                            exercise.questions[index]
                                              .matchingSet.leftColumn[
                                              matchIndex
                                            ]
                                          }
                                        </strong>{" "}
                                        ➔{" "}
                                        {
                                          exercise.questions[index].matchingSet
                                            .rightColumn[
                                            answer.answer.split(",")[matchIndex]
                                          ]
                                        }
                                      </p>
                                    )
                                  )}
                                </div>

                                <p className="mb-2 mt-4">
                                  <strong>{t("Correct Answer")}:</strong>
                                </p>
                                <hr className="border-black border-2 mb-2" />

                                <div className="mb-2">
                                  {exercise.questions[
                                    index
                                  ].matchingSet.correctMatches.map(
                                    (match, matchIndex) => (
                                      <p key={matchIndex}>
                                        <strong>
                                          {
                                            exercise.questions[index]
                                              .matchingSet.leftColumn[
                                              matchIndex
                                            ]
                                          }
                                        </strong>{" "}
                                        ➔{" "}
                                        {
                                          exercise.questions[index].matchingSet
                                            .rightColumn[
                                            exercise.questions[index]
                                              .matchingSet.correctMatches[
                                              matchIndex
                                            ].rightIndex
                                          ]
                                        }
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                            {exercise.type === "fill_in_the_blanks" && (
                              <>
                                <p className="mb-2">
                                  <strong>{t("Student's Answer")}:</strong>
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: exercise.questions[
                                      index
                                    ].text.replace(
                                      /(__\d+__)/g,
                                      (match, p1, offset) => {
                                        const blankIndex =
                                          parseInt(p1.replace(/__/g, "")) - 1;
                                        return `<span class="font-bold underline">${
                                          answer.answer[blankIndex] || "_____"
                                        }</span>`;
                                      }
                                    ),
                                  }}
                                ></p>
                                <p className="mb-2">
                                  <strong>{t("Correct Answer")}:</strong>
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: exercise.questions[
                                      index
                                    ].text.replace(
                                      /(__\d+__)/g,
                                      (match, p1, offset) => {
                                        const blankIndex =
                                          parseInt(p1.replace(/__/g, "")) - 1;
                                        return `<span class="font-bold underline">${exercise.questions[index].blanks[blankIndex].answer}</span>`;
                                      }
                                    ),
                                  }}
                                ></p>
                              </>
                            )}
                            <div className="flex items-center space-x-2">
                              <p className="mb-2">
                                <strong>{t("Grade")}:</strong>
                              </p>
                              <div className="_flex-1"></div>
                              {!selectedStudent.correctedAt && (
                                <button
                                  className="p-2 bg-red-500 border-4 border-black rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:bg-red-600 transition-colors"
                                  onClick={() =>
                                    handleCorrectionChange(index, "score", "1")
                                  }
                                >
                                  <FaThumbsDown className="text-white text-xl" />
                                </button>
                              )}
                              <NeoBrutalistSelect
                                labelOptionText={t("Set a score")}
                                labelOption={true}
                                options={Array.from(
                                  { length: settings.gradeTotal + 1 },
                                  (_, i) => ({
                                    value: i,
                                    label: `${i}/${settings.gradeTotal}`,
                                  })
                                )}
                                onChange={(e) =>
                                  handleCorrectionChange(
                                    index,
                                    "score",
                                    e.target.value
                                  )
                                }
                                value={
                                  corrections[selectedStudent._id] &&
                                  corrections[selectedStudent._id][index] !==
                                    undefined &&
                                  corrections[selectedStudent._id][index]
                                    .score !== ""
                                    ? corrections[selectedStudent._id][index]
                                        .score
                                    : ""
                                }
                                readonly={selectedStudent.correctedAt}
                              />
                              {!selectedStudent.correctedAt && (
                                <button
                                  className="-mt-2 p-2 bg-green-500 border-4 border-black rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:bg-green-600 transition-colors"
                                  onClick={() =>
                                    handleCorrectionChange(
                                      index,
                                      "score",
                                      settings.gradeTotal.toString()
                                    )
                                  }
                                >
                                  <FaThumbsUp className="text-white text-xl" />
                                </button>
                              )}
                            </div>
                            <NeoBrutalistTextArea
                              label={t("Feedback")}
                              value={
                                corrections[selectedStudent._id][index].feedback
                              }
                              onChange={(e) =>
                                handleCorrectionChange(
                                  index,
                                  "feedback",
                                  e.target.value
                                )
                              }
                              readonly={selectedStudent.correctedAt}
                            />
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="mb-4">
                      <NeoBrutalistTextArea
                        label={t("General Feedback")}
                        value={overallFeedback}
                        onChange={(e) => setOverallFeedback(e.target.value)}
                        readonly={selectedStudent.correctedAt}
                        style={{ minHeight: "240px" }}
                      />
                    </div>

                    <div className="border-4 border-black p-4 rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                      <h3 className="text-xl font-bold mb-2">
                        {t("Total Grade")}
                      </h3>

                      <p className="text-2xl font-bold">
                        {totalScore.toFixed(0) + "/" + settings.gradeTotal}
                      </p>
                    </div>

                    {selectedStudent && selectedStudent.correctedAt ? (
                      <div className="border-4 border-yellow-500 bg-yellow-500 p-4 rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] mt-4">
                        <p className="text-black font-bold">
                          {t("This reply has already been corrected.")}
                        </p>
                      </div>
                    ) : selectedStudent && !selectedStudent.correctedAt ? (
                      <div className="border-4 border-yellow-500 bg-yellow-500 p-4 rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] mt-4">
                        <p className="text-black font-bold">
                          {t("This correction has not been submitted yet.")}
                        </p>
                      </div>
                    ) : null}

                    {error && (
                      <div className="border-4 border-red-500 p-4 rounded-lg shadow-[4px_4px_0px_0px_rgba(255,0,0,1)] mt-4">
                        <p className="text-red-500 font-bold">{error}</p>
                      </div>
                    )}
                    <div className="flex justify-end mt-4 space-x-4">
                      {selectedStudent && !selectedStudent.correctedAt && (
                        <NeoBrutalistButton
                          onClick={handleAutoCorrection}
                          style="secondary"
                          disabled={isAutoCorrectingLoading}
                        >
                          <FaWizardsOfTheCoast className="w-4 h-4 mr-2" />
                          {isAutoCorrectingLoading
                            ? t("Auto-correcting...")
                            : t("Auto Correction")} (1 <FaCoins className="ml-2" />)
                        </NeoBrutalistButton>
                      )}
                      {/* {selectedStudent && selectedStudent.correctedAt && (
                        <NeoBrutalistButton
                          onClick={handleDownloadCorrection}
                          style="secondary"
                        >
                          <DownloadIcon className="w-4 h-4 mr-2" />
                          {t("Download")}
                        </NeoBrutalistButton>
                      )} */}
                      {selectedStudent && selectedStudent.correctedAt && (
                        <NeoBrutalistButton
                          onClick={handleCopyLink}
                          style="secondary"
                        >
                          <ClipboardIcon className="w-4 h-4 mr-2" />
                          {t("Copy Correction Link")}
                        </NeoBrutalistButton>
                      )}
                      {selectedStudent && !selectedStudent.correctedAt && (
                        <NeoBrutalistButton
                          onClick={handleSubmitCorrection}
                          style="primary"
                        >
                          <PaperAirplaneIcon className="w-4 h-4 mr-2" />
                          {t("Submit Correction")}
                        </NeoBrutalistButton>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="p-4 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] text-center max-w-lg mx-auto mb-4 mt-8">
                    <p className="text-xl font-bold">
                      {t("This student has not completed the exercise yet.")}
                    </p>
                  </div>
                )
              ) : (
                <>
                  {exerciseReplies.length == 0 ? (
                    <div className="p-4 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] text-center max-w-sm mx-auto mb-4">
                      <p className="text-xl font-bold">
                        {t("No students have completed this exercise yet.")}
                      </p>
                    </div>
                  ) : (
                    <p className="uppercase  text-start text-xl font-bold pt-14 pl-8">
                      {t("Select a student to start correction")}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {showNotification && (
            <NeoBrutalistNotification
              message={notificationMessage}
              onClose={() => setShowNotification(false)}
            />
          )}
        </>
      )}
    </NeoBrutalistModal>
  );
};

export default ExerciseCorrection;
