import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NeoBrutalistInput from '../components/NeoBrutalistInput';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import AvatarSelector from '../components/AvatarSelector';
import { axiosInstance } from '../axiosConfig';
import logger from '../utils/logger';
import { useAuth } from "../contexts/AuthProvider";
import { useContext } from 'react';
import PageTitle from "../components/PageTitle";

const ProfilePage = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const [profile, setProfile] = useState({
    username: userInfo.name || ''   ,
    email: userInfo.email || '',
    avatar: userInfo.avatar || '',
    createdAt: userInfo.createdAt || ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    //fetchProfile();
  }, []);

  useEffect(() => {
    console.log(userInfo);
    if(userInfo && userInfo.name) {
      setProfile(prevProfile => ({
        ...prevProfile,
        username: userInfo.name
      }));
    }
  }, [userInfo]);

  /*const fetchProfile = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/profile`);
      setProfile(response.data);
      setIsLoading(false);
    } catch (error) {
      logger.error("Erreur lors de la récupération du profil:", error);
      setError(t("Failed to load profile"));
      setIsLoading(false);
    }
  };*/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleAvatarChange = (avatar) => {
    setProfile(prevProfile => ({
      ...prevProfile,
      avatar: avatar
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      await axiosInstance.put(`${process.env.REACT_APP_API_URL}/profile`, {
        username: profile.username,
        avatar: profile.avatar
      });
      setSuccessMessage(t("Profile updated successfully"));
    } catch (error) {
      logger.error("Erreur lors de la mise à jour du profil:", error);
      setError(t("Failed to update profile"));
    }
  };

  if (isLoading) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col">
      {/* <Header /> */}
      <main className="flex-grow container mx-auto px-4 pb-8">
      <PageTitle>{t("My Profile")}</PageTitle>
        <div className="bg-white border-4 border-black p-6 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] max-w-2xl mx-auto">
          <form onSubmit={handleSubmit}>
            <div className='flex  gap-4'>
                <div className='flex-1'>
            <NeoBrutalistInput
              label={t("Username")}
              name="username"
              value={profile.username}
              onChange={handleInputChange}
              containerClassName="flex-1"
            />
            </div>
            {/* <NeoBrutalistInput
              label={t("Email")}
              name="email"
              value={profile.email}
              readOnly
            /> */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 text-end align-end justify-end">
                {t("Registration Date")}
              </label>
              <p className='text-end bg-yellow-300 border-4 border-black p-2 inline-block transform -rotate-3 font-bold text-xl shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]'>
                {new Date(profile.createdAt).toLocaleDateString()}
              </p>
            </div>
            </div>
            <AvatarSelector
              selectedAvatar={profile.avatar}
              onSelectAvatar={handleAvatarChange}
              avatarPrefix="/img/avatars/teachers/teacher_"
              avatarCount={6}
              teachers={true}
            />
            <div className="flex justify-end mt-6">
              <NeoBrutalistButton type="submit">
                {t("Update Profile")}
              </NeoBrutalistButton>
            </div>
          </form>
          {error && (
            <div className="mt-4 text-red-500 bg-red-100 border-2 border-red-500 p-4 rounded-lg">
              {error}
            </div>
          )}
          {successMessage && (
            <div className="mt-4 text-green-500 bg-green-100 border-2 border-green-500 p-4 rounded-lg">
              {successMessage}
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProfilePage;