import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import NeoBrutalistInput from "./NeoBrutalistInput";
import NeoBrutalistSelect from "./NeoBrutalistSelect";
import NeoBrutalistTextArea from "./NeoBrutalistTextArea";
import NeoBrutalistButton from "./NeoBrutalistButton";
import NeoBrutalistImageUpload from "./NeoBrutalistImageUpload";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  DocumentRemoveIcon,
  FolderRemoveIcon,
  PlusIcon,
  StopIcon,
} from "@heroicons/react/solid";
import NeoBrutalistModal from "./NeoBrutalistModal";

const ExerciseEditor = ({ exercise, onSave, onClose }) => {
  const { t } = useTranslation();
  const [editedExercise, setEditedExercise] = useState(exercise);
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleQuestion = (index) => {
    setOpenQuestionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleMatchingSetChange = (questionIndex, setIndex, field, value) => {
    const updatedQuestions = [...editedExercise.questions];
    updatedQuestions[questionIndex].matchingSets[setIndex][field] = value;
    setEditedExercise((prev) => ({ ...prev, questions: updatedQuestions }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedExercise((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...editedExercise.questions];
    updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
    setEditedExercise((prev) => ({ ...prev, questions: updatedQuestions }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await onSave(editedExercise);
      setIsLoading(false);
      // Passez à l'onglet de révision après la sauvegarde
      setSelectedIndex(2);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
      setIsLoading(false);
    }
  };

  const modalFooter = (
    <div className="flex justify-end space-x-4">
      <NeoBrutalistButton onClick={onClose} style="secondary" size="small">
        <StopIcon className="h-4 w-4 mr-2" />
        {t("Cancel")}
      </NeoBrutalistButton>
      <NeoBrutalistButton onClick={handleSave} style="primary" size="small">
        <CheckIcon className="h-4 w-4 mr-2" />
        {t("Save")}
      </NeoBrutalistButton>
    </div>
  );

  const addQuestion = () => {
    const newQuestion = {
      question: "",
      choices: ["", "", "", ""],
      answer: 0,
    };
    setEditedExercise((prev) => ({
      ...prev,
      questions: [...prev.questions, newQuestion],
    }));
    setOpenQuestionIndex(editedExercise.questions.length);
  };

  const removeQuestion = (index) => {
    setEditedExercise((prev) => ({
      ...prev,
      questions: prev.questions.filter((_, i) => i !== index),
    }));
    setOpenQuestionIndex(null);
  };

  return (
    <NeoBrutalistModal
      isOpen={true}
      onClose={onClose}
      title={t('Edit exercise')}
      footer={modalFooter}
    >
      {isLoading ? (
        <Loader
          title={t("Reviewing exercise")}
          description={t(
            "Please wait while our A.I expert reviews your exercise"
          )}
        />
      ) : (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex space-x-2 mb-4">
            <Tab
              className={({ selected }) =>
                `w-full py-2 text-lg font-bold border-4 border-black rounded-none transition-all
      ${
        selected
          ? "bg-yellow-400 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] text-black"
          : "bg-white hover:bg-yellow-200"
      }`
              }
            >
              {t("General")}
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full py-2 text-lg font-bold border-4 border-black rounded-none transition-all
      ${
        selected
          ? "bg-yellow-400 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] text-black"
          : "bg-white hover:bg-yellow-200"
      }`
              }
            >
              {t("Questions")}
            </Tab>
      
          </Tab.List>
          <Tab.Panels>
            {/* Le contenu des panneaux reste inchangé */}
            <Tab.Panel>
              <NeoBrutalistInput
                label="Titre"
                name="title"
                value={editedExercise.title}
                onChange={handleInputChange}
              />
              <NeoBrutalistInput
                label="Niveau"
                name="level"
                value={t(editedExercise.level)}
                onChange={handleInputChange}
                // options={[
                //   { value: "primary_school", label: "École primaire" },
                //   { value: "middle_school", label: "Collège" },
                //   { value: "high_school", label: "Lycée" },
                //   { value: "university", label: "Université" },
                // ]}
              />
              {/* <NeoBrutalistSelect
              label="Type"
              name="type"
              value={editedExercise.type}
              onChange={handleInputChange}
              options={[
                { value: "multiple_choice", label: "Choix multiple" },
                { value: "free_writing", label: "Rédaction libre" },
              ]}
            /> */}
              <NeoBrutalistTextArea
                label="Description"
                name="description"
                value={editedExercise.description}
                onChange={handleInputChange}
              />
             
              {editedExercise.illustration && (
                <div className="mt-4">
                  <h3 className="text-xl font-bold mb-2">Illustration</h3>
                  <img
                    src={editedExercise.illustration}
                    alt="Illustration de l'exercice"
                    className="w-full max-w-md mx-auto border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]"
                  />
                </div>
              )}
            </Tab.Panel>
            <Tab.Panel>
            <NeoBrutalistTextArea
                label="Instructions"
                name="text"
                value={editedExercise.text}
                onChange={handleInputChange}
              />
              {editedExercise.questions.map((question, index) => (
                <div key={index} className="mb-4">
                  <div
                    className={`flex justify-between items-center p-4 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] cursor-pointer hover:bg-yellow-400 ${
                      openQuestionIndex === index ? "bg-yellow-400" : "bg-white"
                    }`}
                    onClick={() => toggleQuestion(index)}
                  >
                    <h3 className="text-lg md:text-2xl font-bold">{t('Question')} {index + 1}</h3>
                    <div className="flex-1 space-x-2"></div>
                    <span className="text-2xl ml-8">
                      {openQuestionIndex === index ? "▲" : "▼"}
                    </span>
                  </div>
                  {openQuestionIndex === index && (
                    <div
                      className="mt-4 p-4 bg-grey-200
                           border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]"
                    >
                      <NeoBrutalistInput
                        label={`Question ${index + 1}`}
                        name={`question-${index}`}
                        value={question.question}
                        onChange={(e) =>
                          handleQuestionChange(
                            index,
                            "question",
                            e.target.value
                          )
                        }
                      />
                      {editedExercise.type === "matching" && (
  <div className="mt-4">
    <h4 className="text-xl font-semibold mb-2">{t("Matching")}</h4>
    {question.leftColumn.map((item, itemIndex) => (
      <div key={itemIndex} className="flex space-x-4 mb-2">
        <NeoBrutalistInput
          label={`${t("Left Column")} ${itemIndex + 1}`}
          name={`leftColumn-${index}-${itemIndex}`}
          value={item}
          onChange={(e) => {
            const newLeftColumn = [...question.leftColumn];
            newLeftColumn[itemIndex] = e.target.value;
            handleQuestionChange(index, "leftColumn", newLeftColumn);
          }}
        />
        <NeoBrutalistInput
          label={`${t("Right Column")} ${itemIndex + 1}`}
          name={`rightColumn-${index}-${itemIndex}`}
          value={question.rightColumn[itemIndex]}
          onChange={(e) => {
            const newRightColumn = [...question.rightColumn];
            newRightColumn[itemIndex] = e.target.value;
            handleQuestionChange(index, "rightColumn", newRightColumn);
          }}
        />
        <NeoBrutalistInput
          label={`${t("Match")} ${itemIndex + 1}`}
          name={`match-${index}-${itemIndex}`}
          type="number"
          value={question.correctMatches[itemIndex]}
          onChange={(e) => {
            const newCorrectMatches = [...question.correctMatches];
            newCorrectMatches[itemIndex] = parseInt(e.target.value);
            handleQuestionChange(index, "correctMatches", newCorrectMatches);
          }}
        />
      </div>
    ))}
    <NeoBrutalistButton
      onClick={() => {
        const newLeftColumn = [...question.leftColumn, ""];
        const newRightColumn = [...question.rightColumn, ""];
        const newCorrectMatches = [...question.correctMatches, 0];
        handleQuestionChange(index, "leftColumn", newLeftColumn);
        handleQuestionChange(index, "rightColumn", newRightColumn);
        handleQuestionChange(index, "correctMatches", newCorrectMatches);
      }}
      style="secondary"
      size="small"
      className="mt-4"
    >
      {t("Add Matching Pair")}
    </NeoBrutalistButton>
  </div>
)}
   {exercise.type === 'matching' && (
      <div>
                  {editedExercise.questions.map((question, questionIndex) => (
          <div key={questionIndex}>
            <NeoBrutalistTextArea
              label={`Instruction pour la question ${questionIndex + 1}`}
              value={question.instruction}
              onChange={(e) => handleQuestionChange(questionIndex, 'instruction', e.target.value)}
            />
            {question.matchingSets.map((set, setIndex) => (
              <div key={setIndex}>
                <NeoBrutalistInput
                  label={`Titre de la colonne de gauche (Set ${setIndex + 1})`}
                  value={set.leftColumnTitle}
                  onChange={(e) => handleMatchingSetChange(questionIndex, setIndex, 'leftColumnTitle', e.target.value)}
                />
                <NeoBrutalistInput
                  label={`Titre de la colonne de droite (Set ${setIndex + 1})`}
                  value={set.rightColumnTitle}
                  onChange={(e) => handleMatchingSetChange(questionIndex, setIndex, 'rightColumnTitle', e.target.value)}
                />
                {/* ... autres champs pour leftColumn, rightColumn, correctMatches ... */}
                <NeoBrutalistTextArea
                  label={`Colonne de gauche (Set ${setIndex + 1})`}
                  value={set.leftColumn.join('\n')}
                  onChange={(e) => handleMatchingSetChange(questionIndex, setIndex, 'leftColumn', e.target.value.split('\n'))}
                />
                <NeoBrutalistTextArea
                  label={`Colonne de droite (Set ${setIndex + 1})`}
                  value={set.rightColumn.join('\n')}
                  onChange={(e) => handleMatchingSetChange(questionIndex, setIndex, 'rightColumn', e.target.value.split('\n'))}
                />
                <NeoBrutalistTextArea
                  label={`Correspondances correctes (Set ${setIndex + 1})`}
                  value={set.correctMatches.join('\n')}
                  onChange={(e) => handleMatchingSetChange(questionIndex, setIndex, 'correctMatches', e.target.value.split('\n').map(Number))}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    )}
                {editedExercise.type === "fill_in_the_blanks" && (
  <div className="mt-4">
    <h4 className="text-xl font-semibold mb-2">{t("Texts with blanks")}</h4>
    {question.text && (
      <NeoBrutalistTextArea
        label={t("Text")}
        name={`text-${index}`}
        value={question.text}
        onChange={(e) => handleQuestionChange(index, "text", e.target.value)}
        className="mt-4"
      />
    )}
    <h4 className="text-xl font-semibold mb-2 mt-4">{t("Blanks")}</h4>
    {question.blanks && question.blanks.map((blank, blankIndex) => (
      <div key={blankIndex} className="mt-4">
        <NeoBrutalistInput
          label={t("Blank")}
          name={`blank-${index}-${blankIndex}`}
          value={blank.blank}
          onChange={(e) => {
            const newBlanks = [...question.blanks];
            newBlanks[blankIndex].blank = e.target.value;
            handleQuestionChange(index, "blanks", newBlanks);
          }}
        />
        <NeoBrutalistInput
          label={t("Answer")}
          name={`answer-${index}-${blankIndex}`}
          value={blank.answer}
          onChange={(e) => {
            const newBlanks = [...question.blanks];
            newBlanks[blankIndex].answer = e.target.value;
            handleQuestionChange(index, "blanks", newBlanks);
          }}
        />
        <NeoBrutalistTextArea
          label={t("Answer Explanation")}
          name={`answerExplanation-${index}-${blankIndex}`}
          value={blank.answerExplanation}
          onChange={(e) => {
            const newBlanks = [...question.blanks];
            newBlanks[blankIndex].answerExplanation = e.target.value;
            handleQuestionChange(index, "blanks", newBlanks);
          }}
        />
      </div>
    ))}
    <NeoBrutalistButton
      onClick={() => {
        const newBlanks = [...(question.blanks || []), { blank: "", answer: "", answerExplanation: "" }];
        handleQuestionChange(index, "blanks", newBlanks);
      }}
      style="secondary"
      size="small"
      className="mt-4"
    >
      {t("Add Blank")}
    </NeoBrutalistButton>
  </div>
)}
                      {editedExercise.type === "multiple_choice" ? (
                        <div className="mt-4">
                          <h4 className="text-xl font-semibold mb-2">Choix</h4>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {question.choices.map((choice, choiceIndex) => (
                              <NeoBrutalistInput
                                key={choiceIndex}
                                label={`Choix ${choiceIndex + 1}`}
                                name={`choice-${index}-${choiceIndex}`}
                                value={choice}
                                onChange={(e) => {
                                  const newChoices = [...question.choices];
                                  newChoices[choiceIndex] = e.target.value;
                                  handleQuestionChange(
                                    index,
                                    "choices",
                                    newChoices
                                  );
                                }}
                              />
                            ))}
                          </div>
                          <NeoBrutalistSelect
                            label="Réponse correcte"
                            name={`answer-${index}`}
                            value={question.answer}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "answer",
                                e.target.value
                              )
                            }
                            options={question.choices.map(
                              (choice, choiceIndex) => ({
                                value: choiceIndex.toString(),
                                label: choice,
                              })
                            )}
                            className="mt-4"
                          />
                        </div>
                      ) : (
                        <NeoBrutalistTextArea
                          label="Instructions de correction"
                          name={`answer-${index}`}
                          value={question.answer}
                          onChange={(e) =>
                            handleQuestionChange(
                              index,
                              "answer",
                              e.target.value
                            )
                          }
                          className="mt-4"
                        />
                      )}
                       {editedExercise.type === "multiple_choice" && (
                           <NeoBrutalistTextArea
                               label="Answer Explanation"
                               name={`answerExplanation-${index}`}
                               value={question.answerExplanation}
                               onChange={(e) =>
                                   handleQuestionChange(
                                       index,
                                       "answerExplanation",
                                       e.target.value
                                   )
                               } 
                               className="mt-4"
                           />
                       )}
                      <div className="flex justify-end">
                        <NeoBrutalistButton
                          onClick={() => removeQuestion(index)}
                          style="secondary"
                          size="small"
                        >
                          <DocumentRemoveIcon className="h-4 w-4 mr-2" />
                          {t('Delete')}
                        </NeoBrutalistButton>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="mt-4 flex justify-end">
                <NeoBrutalistButton
                  onClick={addQuestion}
                  style="primary"
                  size="small"
                >
                  <PlusIcon className="h-4 w-4 mr-2" />
                  Add a question
                </NeoBrutalistButton>
              </div>
            </Tab.Panel>
          
          </Tab.Panels>
        </Tab.Group>
      )}
    </NeoBrutalistModal>
  );
};

export default ExerciseEditor;
