import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import NeoBrutalistInput from '../components/NeoBrutalistInput';
import NeoBrutalistTextArea from '../components/NeoBrutalistTextArea';
import NeoBrutalistModal from '../components/NeoBrutalistModal';
import NeoBrutalistSelect from '../components/NeoBrutalistSelect';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import logger from '../utils/logger';

const StudentExercisePage = ({loadedExercise, isPreview = false, onClosePreview=() => {} }) => {
  const { uniqueLink } = useParams();
  const { t } = useTranslation();
  //const [exercise, setExercise] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('url(/img/background1.webp)');
  const [isCompleted, setIsCompleted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [showIntroduction, setShowIntroduction] = useState(true);
  const [exercise, setExercise] = useState(loadedExercise || null);
  const [teacherName, setTeacherName] = useState('');

  useEffect(() => {
    if(uniqueLink){
    const fetchStudent = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/myName/${uniqueLink}`);
      setFirstName(response.data.firstName);
    };
    fetchStudent();


    const backgroundImages = ['background1.webp', 'background2.webp', 'background3.webp', 'background4.webp'];
    const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
    setBackgroundImage(`url(/img/${randomImage})`);
  }
  }, [uniqueLink]);

  useEffect(() => {
    if(uniqueLink){
    const fetchExercise = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/exercise-reply/${uniqueLink}`);
        setExercise(response.data.exercise);
        setIsCompleted(response.data.completed);
  
        // Déterminer la question actuelle en fonction des réponses déjà soumises
        if (response.data.answers && response.data.answers.length > 0) {
          setCurrentQuestionIndex(response.data.answers.length);
        }
  
      } catch (error) {
        logger.error("Erreur lors de la récupération de l'exercice:", error);
        setError(t("The exercise could not be loaded. Please try again later."));
      }
    };
  
    fetchExercise();
    }
   // const randomImageNumber = Math.floor(Math.random() * 5) + 1;
   // setBackgroundImage('url(/img/00024.webp)');
  }, [uniqueLink]);

  useEffect(() => {
    console.log("loadedExercise", loadedExercise);
    if(loadedExercise){
      fetchTeacherName();
      setExercise(loadedExercise);
    }
  }, [loadedExercise]);

  const fetchTeacherName = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/myName`);
    setTeacherName(response.data.name);
  };

  const handleAnswerChange = (e) => {
    if (exercise.type === 'multiple_choice' || exercise.type === 'free_writing') {
      setAnswer(e.target.value);
    }
  };
  
  const handleMatchingChange = (setIndex, itemIndex, value) => {
    setAnswer((prev) => {
      const newAnswer = [...(prev || [])];
      newAnswer[itemIndex] = value;
      return newAnswer;
    });
  };

  
  const handleFillInTheBlanksChange = (index, value) => {
    setAnswer((prev) => {
      const newAnswer = [...(prev || [])];
      newAnswer[index] = value;
      return newAnswer;
    });
  };

  const handleNextQuestion = async () => {
    if (showIntroduction) {
      setShowIntroduction(false);
      return;
    }
  
    logger.log("answer", answer);
    if (!isAnswerValid()) {
      setError(t('Please answer the question before continuing.'));
      return;
    }

  
    try {
      if (!isPreview) {
      await axios.post(`${process.env.REACT_APP_API_URL}/exercise-reply/${uniqueLink}/submit-answer`, {
        questionIndex: currentQuestionIndex,
        answer: answer
      });
    }
  
      if (currentQuestionIndex < exercise.questions.length - 1) {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        setAnswer(exercise.type === 'matching' || exercise.type === 'fill_in_the_blanks' ? [] : '');
      } else {
        setIsSubmitted(true);
      }
      setError('');
    } catch (error) {
      logger.error("Erreur lors de la soumission de la réponse:", error);
      setError(t("The answer could not be submitted. Please try again later."));
    }
  };
  
  const isAnswerValid = () => {
    if (exercise.type === 'multiple_choice' || exercise.type === 'free_writing') {
      return answer.trim() !== '';
    } else if (exercise.type === 'matching') {
      return answer && answer.length === exercise.questions[currentQuestionIndex].matchingSet.leftColumn.length && answer.every(item => item !== undefined && item !== '');
    } else if (exercise.type === 'fill_in_the_blanks') {
      console.log("answer", answer);
      if(answer.length === 0) {
        return false;
      }
      return answer.every(item => item && item.trim() !== '');
    }
    return false;
  };

  const renderMultipleChoice = (question) => (
    <div className="space-y-4">
      {question.choices.map((choice, choiceIndex) => (
        <div key={choiceIndex} className="flex items-center space-x-3">
          <input
            type="radio"
            id={`choice${choiceIndex}`}
            name="answer"
            value={choiceIndex.toString()}
            checked={answer === choiceIndex.toString()}
            onChange={handleAnswerChange}
            className="form-radio h-5 w-5 text-blue-600 border-2 border-black"
          />
          <label htmlFor={`choice${choiceIndex}`} className="text-lg">{choice}</label>
        </div>
      ))}
    </div>
  );
  
  const renderFreeWriting = () => (
    <NeoBrutalistTextArea
      value={answer}
      onChange={handleAnswerChange}
      placeholder={t("Your answer")}
    />
  );
  
function renderMatching(question) {
  const { matchingSet } = question;
  return (
    <div className="space-y-6">
      <div className="border-4 border-black p-4 rounded-lg">
        <h3 className="text-xl font-bold mb-4">
          {matchingSet.leftColumnTitle} - {matchingSet.rightColumnTitle}
        </h3>
        <div className="space-y-2">
          {matchingSet.leftColumn.map((leftItem, itemIndex) => (
            <div key={itemIndex} className="flex items-center space-x-4">
              <div className="w-1/2 bg-yellow-200 border-4 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] px-4 py-2 relative -top-1 ">
                {leftItem}
              </div>
              <div className="w-1/2 flex items-center">
                <NeoBrutalistSelect
                  className="w-full"
                  options={matchingSet.rightColumn.map((rightItem, rightIndex) => ({
                    value: rightIndex.toString(),
                    label: rightItem,
                  }))}
                  value={
                    answer[itemIndex]
                      ? answer[itemIndex]
                      : ''
                  }
                  onChange={(e) =>
                    handleMatchingChange(0, itemIndex, e.target.value)
                  }
                  placeholder={t('Select match')}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
  

 const renderFillInTheBlanks = (question) => {
  const parts = question.text.split(/(__\d+__)/);
  return (
    <div className="space-y-4">
      {parts.map((part, index) => {
        const match = part.match(/__(\d+)__/);
        if (match) {
          const holeIndex = parseInt(match[1]) - 1;
          return (
            <NeoBrutalistInput
              key={index}
              value={answer[holeIndex] || ''}
              onChange={(e) => handleFillInTheBlanksChange(holeIndex, e.target.value)}
              placeholder={t("Remplir")}
              inline={true}
            />
          );
        }
        return <span key={index}>{part}</span>;
      })}
    </div>
  );
};


  const renderQuestion = () => {
    if (!exercise || !exercise.questions[currentQuestionIndex]) return null;
  
    const question = exercise.questions[currentQuestionIndex];
    return (
      <div className="bg-white border-4 border-black p-6 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
        <h2 className="text-2xl font-bold mb-4">{t('Question')} {currentQuestionIndex + 1}</h2>
        <p className="mb-6 text-lg">{question.question}</p>
        {exercise.type === 'multiple_choice' && renderMultipleChoice(question)}
        {exercise.type === 'free_writing' && renderFreeWriting()}
        {exercise.type === 'matching' && renderMatching(question)}
        {exercise.type === 'fill_in_the_blanks' && renderFillInTheBlanks(question)}
      </div>
    );
  };

  const modalContent = () => {
  if (isCompleted) {
    return ( 
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-4">{t('EXERCISE COMPLETED!')}</h2>
        <p className="text-xl mb-6">{t('You have finished this exercise. Congratulations!')}</p>
        <div className="border-4 border-black p-4 inline-block transform rotate-3 bg-green-400">
          <span className="text-2xl font-bold">🎉 {t('FINISHED!')} 🎉</span>
        </div>
      </div>
    );
  }
  if (isSubmitted) {
    return (
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-4">{t('EXERCISE COMPLETED!')}</h2>
        <p className="text-xl mb-6">{t('You have finished this exercise. Congratulations!')}</p>
        <div className="border-4 border-black p-4 inline-block transform rotate-3 bg-green-400">
          <span className="text-2xl font-bold">🎉 {t('FINISHED!')} 🎉</span>
        </div>
      </div>
    );
  }

  if (showIntroduction) {
    return (
      <>
        <h1 className="text-3xl font-bold mb-6">{t('Hello')} {isPreview?teacherName:firstName}, {t('here is your exercise')}</h1>
        <h2 className="text-2xl font-bold mb-4">{exercise?.title}</h2>
        <p className="mb-6">{exercise?.text}</p>
        {/* {exercise?.illustration && (
          <img src={exercise.illustration} alt="Exercise illustration" className="mb-6 max-w-full h-auto" />
        )} */}
        <div className="mt-6 flex justify-end">
          <NeoBrutalistButton onClick={() => setShowIntroduction(false)} style="primary" className='max-w-4xl'>
            {t('Start Exercise')}
          </NeoBrutalistButton>
        </div>
      </>
    );
  }

  return (
    <>
      {renderQuestion()}
      <div className="mt-6 flex flex-col sm:flex-row justify-end">
        {error && <><p className="text-red-500 font-bold mt-4 mb-2">{error}</p><div className='flex-1'></div></>}
        <NeoBrutalistButton onClick={handleNextQuestion} style="primary" className="mt-4 sm:mt-0 sm:ml-4">
          {currentQuestionIndex < exercise?.questions.length - 1 ? t('Continue') : t('Finish')}
        </NeoBrutalistButton>
      </div>
    </>
  );
};

  if (!exercise) {
    return <div>{t('Loading exercise...')}</div>;
  }

  const handleCloseModal= ()=>{
    setIsModalOpen(false);
    if(isPreview){
      onClosePreview();
    }
  }

  return (
    <>
    <div style={{
        backgroundImage: backgroundImage,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}>
    <NeoBrutalistModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      closable={isPreview?true:false}
      size='lg'
      title={isCompleted ? t('EXERCISE COMPLETED!') : `${exercise.title}`}
    >
      {modalContent()}
    </NeoBrutalistModal>
    </div>
    <Footer/>
    </>
  );
};

export default StudentExercisePage;