import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import GeneratorPage from "./components/ExerciseGenerator";
import HomePage from "./pages/HomePage";
import MyExercisesPage from "./pages/MyExercisesPage";
import StudentExercisePage from "./pages/StudentExercisePage";
import StudentLessonPage from "./pages/StudentLessonPage";
import ProtectedRoute from "./components/ProtectedRoute";
import axios from "axios";
import SettingsPage from "./pages/SettingsPage";
import StudentCorrectionPage from "./pages/StudentCorrectionPage";
import MyStudentsPage from "./pages/MyStudentsPage";
import { useNavigate } from "react-router-dom";
import { setupAxiosInterceptors } from "./axiosConfig";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import DashboardPage from "./pages/DashboardPage"; // Importez le nouveau composant DashboardPage
import MyLessonsPage from "./pages/MyLessonsPage";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import PricingPage from "./pages/PricingPage";
import ContactPage from "./pages/ContactPage";
import logger from "./utils/logger";
import LegalNoticePage from "./pages/LegalNoticePage";
import { useAuth } from "./contexts/AuthProvider";
import DocumentationPage from "./pages/DocumentationPage";
import ProfilePage from "./pages/ProfilePage";
import MyPlan from "./pages/MyPlan";
import Header from './components/Header';
import { NotificationProvider } from "./contexts/NotificationContext";
import Notification from './components/Notification';
import LessonGeneratorPage from "./components/LessonGenerator";
import GeneratorsPage from "./pages/GeneratorsPage";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function AppContent() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth(); 
  const { userInfo, checkAuthStatus } = useAuth();
  const [credits, setCredits] = useState(userInfo?.credits || 0);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (
      !["/", "/contact", "/privacy", "/terms"].includes(currentPath) &&
      !currentPath != "/"
    ) {
      setupAxiosInterceptors(navigate);
    }
  }, [navigate]);

  useEffect(() => {
    setCredits(userInfo.credits);
  }, [userInfo.credits]);

  useEffect(() => {
    console.log("credits", credits);
  }, [credits]);

  const handleCreditsUpdate = (newCredits) => {
    console.log('handleCreditsUpdate '+newCredits);
    setCredits(newCredits);
 //  updateUserInfo({ ...userInfo, credits: newCredits });
 checkAuthStatus();
  };

  // const verifyToken = async (token) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/auth/verify-registration-token?registrationToken=${token}`
  //     );
  //     if (response.data.valid) {
  //       logger.log("Token is valid");
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     } else {
  //       logger.log("Token is invalid");
  //       localStorage.removeItem("token"); // Remove invalid token
  //     }
  //   } catch (error) {
  //     logger.error("Error verifying token:", error);
  //     localStorage.removeItem("token"); // Remove token on error
  //   }
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const currentPath = window.location.pathname;
    if (token && !currentPath.includes("verify-email")) {
      //verifyToken(token);
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <NotificationProvider>
      <Notification />
      <div className="App">
      <Header activeLink="home" credits={credits} />

        <Routes>
        <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard"  replace /> : <HomePage />}
          />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/legal" element={<LegalNoticePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/documentation" element={<DocumentationPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route
            path="/exercise-reply/:uniqueLink"
            element={<StudentExercisePage />}
          />
          <Route
            path="/lesson/:uniqueLink"
            element={<StudentLessonPage />}
          />
          <Route
            path="/correction/:uniqueLink"
            element={<StudentCorrectionPage />}
          />
          <Route
            path="/verify-email/:registrationToken"
            element={<LoginPage />}
          />

          <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardPage onCreditsUpdated={handleCreditsUpdate} />} /> 

            {/* <Route path="/generator" element={<GeneratorPage  onCreditsUpdated={handleCreditsUpdate}  />} /> */}
            <Route path="/generators" element={<GeneratorsPage  onCreditsUpdated={handleCreditsUpdate}  />} />
            {/* <Route path="/lesson-generator" element={<LessonGeneratorPage  onCreditsUpdated={handleCreditsUpdate}  />} /> */}
            <Route path="/my-exercises" element={<MyExercisesPage />} />
            <Route path="/my-lessons" element={<MyLessonsPage />} />
            <Route path="/my-students" element={<MyStudentsPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/my-plan" element={<MyPlan />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/subscription-success"
              element={<SubscriptionSuccessPage />}
            />
          </Route>

          {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
        </Routes>
      </div>
      </NotificationProvider>
    </Elements>
  );
}

export default AppContent;
