import React, { useState, useEffect } from "react";
import { useTranslation, useLanguage } from "react-i18next";
import {
  AcademicCapIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import NeoBrutalistInput from "./NeoBrutalistInput.js";
import NeoBrutalistSelect from "./NeoBrutalistSelect.js";
import NeoBrutalistTextArea from "./NeoBrutalistTextArea.js";
import NeoBrutalistButton from "./NeoBrutalistButton.js";
import NeoBrutalistNotification from "./NeoBrutalistNotification.js";
import axios from "axios";
import Loader from "./Loader.js";
import { useNavigate } from "react-router-dom";
import Header from "./Header.js";
import { axiosInstance } from "../axiosConfig.js";
import Footer from "./Footer.js";
import logger from "../utils/logger.js";
import OpenAIKeyModal from './OpenAIKeyModal.js';
import NeoBrutalistUploadField from "./NeoBrutalistUploadField.js";
import { FaCoins, FaMagic } from "react-icons/fa";
import { useAuth } from "../contexts/AuthProvider.js";
import PageTitle from "./PageTitle.js";
import { useNotification } from '../contexts/NotificationContext.js';


const ExerciseGenerator = ({ onCreditsUpdated, onGenerate = () => {} }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { showNotification } = useNotification();
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(true);
  const [remainingExercises, setRemainingExercises] = useState(null);
  const [estimatedCredits, setEstimatedCredits] = useState(0);

  const [exerciseData, setExerciseData] = useState({
    subject: "",
    instructions: "", // Replaced 'description' with 'instructions'
    level: JSON.parse(process.env.REACT_APP_LEVELS)[0],
    tone: "Neutral",
    type: "multiple_choice",
    language: i18n.language== 'fr' ? 'french' : 'english',
    numberOfQuestions: 7,
    generateLesson: false,
    generateImage: true
    // educationalObjective: '', 
  });
  const [lessonFile, setLessonFile] = useState(null); // Ajouté pour gérer le fichier de leçon

 // const [showNotification, setShowNotification] = useState(false);
  const [showOpenAIKeyModal, setShowOpenAIKeyModal] = useState(false);
const [freeGenerationsLeft, setFreeGenerationsLeft] = useState(5);
const [notificationMessage, setNotificationMessage] = useState("");
const [customSubject, setCustomSubject] = useState("");
const [customLevel, setCustomLevel] = useState("");
const [generateRelatedExercise, setGenerateRelatedExercise] = useState(false);
const [creditCost, setCreditCost] = useState(0);
const [lessonUploadCost,setLessonUploadCost] = useState(0);




const humorousTexts = [
  "Protecting app against little cheaters",
  "Finding sneaky ways to trick students",
  "Automatically rewarding teacher's pets",
  "Boosting the teacher's ego",
  "Sabotaging Kevin's homework",
  "Sharpening pencils for better answers",
  "Charging the brain cells",
  "Calibrating the teacher's red pen",
  "Hiding the answer key",
  "Double-checking for tricky questions",
  "Ensuring no one falls asleep",
  "Polishing the chalkboard",
  "Refilling the ink in virtual pens",
  "Warming up the thinking caps",
  "Making sure calculators are off",
  "Counting the number of erasers"
];
const getRandomHumorousText = () => {
  return t(humorousTexts[Math.floor(Math.random() * humorousTexts.length)]);
};

const [humorousText, setHumorousText] = useState(getRandomHumorousText());



useEffect(() => {
  let interval;
  if (isLoading) {
    interval = setInterval(() => {
      setHumorousText(getRandomHumorousText());
    }, 5000);
  }
  return () => clearInterval(interval);
}, [isLoading]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      
    fetchRemainingCredits();
    }
  }, []);

  const handleFileChange = (sessionId) => {
    setLessonFile(sessionId);
    setIsLoading(false);
  };

  

  
  const handleSaveOpenAIKey = async (key) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/settings/updateOpenAIKey`, { openaiApiKey: key });
      if (response.data.isValid) {
        setShowOpenAIKeyModal(false);
        fetchRemainingCredits();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la clé OpenAI:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        openaiKey: t("Error updating OpenAI key")
      }));
      return false;
    }
  };
  
  const fetchRemainingCredits = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/subscriptions/getRemainingCredits`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setFreeGenerationsLeft(response.data.freeGenerationsLeft);
      if (!response.data.hasOpenAIKey) {
        setShowOpenAIKeyModal(true);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des crédits restants:", error);
    }
  };

  // const fetchRemainingCredits = async () => {
  //   try {
  //     const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/subscriptions/getRemainingCredits`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     setRemainingExercises(response.data.exercisesLeft);
  //   } catch (error) {
  //     logger.error("Erreur lors de la récupération des crédits restants:", error);
  //   }
  // };

  useEffect(() => {
    handleEstimateCredits();
  }, [exerciseData.generateImage, exerciseData.numberOfQuestions, exerciseData.type,lessonUploadCost,step]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExerciseData((prevData) => ({ ...prevData, [name]: value }));
    handleEstimateCredits();
  };
  

  const handleNext = () => {
    const newErrors = {};
    logger.log("step", step);
    if (step === 1) {
      if (!exerciseData.subject.trim())
        newErrors.subject = t("Subject is required");
      /* if (!exerciseData.instructions.trim())
        newErrors.instructions = t("Instructions is required");*/
      if (!exerciseData.level) newErrors.level = t("Level is required");
    }
    if (Object.keys(newErrors).length === 0) {
      if (step < 2) {
        // Vérifiez que nous ne sommes pas à la dernière étape
        setStep((prevStep) => prevStep + 1);
      }
      setErrors({});
    } else {
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    logger.log("handleSubmit");
    if (!acceptTerms) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        terms: t("You must accept the terms and conditions"),
      }));
      return;
    }
    // if (remainingExercises !== null && remainingExercises <= 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     subscriptionLimit: t("You have reached your exercise generation limit. Please upgrade your plan or fill your OpenAI API key in the settings.")
    //   }));
    // setShowNotification(true);
    //   return;
    // }

    const newErrors = {};
    if (!exerciseData.tone) newErrors.tone = t("Tone is required");
    if (!exerciseData.type) newErrors.type = t("Type is required");
    if (!exerciseData.language) newErrors.language = t("Language is required");

    if (Object.keys(newErrors).length === 0) {
      
    onGenerate();
      setIsLoading(true);
      const formData = new FormData();
       
    // Ajouter toutes les données de l'exercice
    Object.keys(exerciseData).forEach(key => {
      formData.append(key, exerciseData[key]);
    });
    
    // Ajouter le fichier de la leçon si présent
    // if (lessonFile) {
    //   formData.append('lessonFile', lessonFile);
    // }
    if (lessonFile) {
      formData.append('lessonSessionId', lessonFile);
    }

    console.log('subject', exerciseData.subject);

      formData.append('subject', exerciseData.subject === "other" ? customSubject : exerciseData.subject);
      formData.append('level', exerciseData.level === "other" ? customLevel : exerciseData.level);
      formData.append('numberOfQuestions', exerciseData.numberOfQuestions || undefined);
      formData.append('generateLesson', exerciseData.generateLesson);
      formData.append('generateImage', exerciseData.generateImage);

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}/exercises/createExercise`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        )
        .then((response) => {
          logger.log("Exercice créé avec succès:", response.data);
          setIsLoading(false);
          if (response.data.costInfo.estimatedCredits !== undefined) {
            onCreditsUpdated(userInfo.credits - response.data.costInfo.estimatedCredits);
          }
          if (window.location.pathname === "/generator") {
            navigate("/my-exercises", {
              state: { newExerciseId: response.data._id },
            });
           }
           showNotification(t("The exercise {{title}} has been generated").replace('{{title}}',response.data.exercise.title));


        })
        .catch((error) => {
          console.log('error', error);
          setIsLoading(false);
          //setShowNotification(true);
          showNotification(t("An error occurred. Please retry later.")); 

         /// setNotificationMessage(t("An error occurred. Please retry later."));
        });
    } else {
      setErrors(newErrors);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleCloseOpenAIKeyModal = (isValid) => {
    console.log('isValid', isValid);
    setShowOpenAIKeyModal(false);
    if (!isValid) {
      navigate("/my-exercises");
    }
  };

  const handleEstimateCredits = (fileCredits = 0) => {
    setIsLoading(false);
    let totalCredits = 1; // Coût de base pour la génération d'exercice
  
    if (exerciseData.generateImage && step >1) {
      totalCredits += 1; // Coût supplémentaire pour la génération d'image
    }
    totalCredits += lessonUploadCost;
    setCreditCost(totalCredits);
    // Ajout des crédits estimés pour le fichier de leçon
    //totalCredits += fileCredits;
  
    // Autres facteurs pouvant influencer le coût...
    // Par exemple, le nombre de questions, le type d'exercice, etc.
  
   // setCreditCost(totalCredits);
  };

  const handleEstimateLesson = (cost)=> {
    setLessonUploadCost(cost);
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <NeoBrutalistSelect
              label={t("Subject")}
              name="subject"
              error={errors.subject}
              value={exerciseData.subject}
              onChange={handleInputChange}
              options={[
                ...JSON.parse(process.env.REACT_APP_SUBJECTS).map(subject => ({ value: subject, label: t(subject) })),
                { value: "other", label: t("Other") }
              ]}
                />
            {exerciseData.subject === "other" && (
              <NeoBrutalistInput
                label={t("Custom Subject")}
                name="customSubject"
                value={customSubject}
                onChange={(e) => setCustomSubject(e.target.value)}
                placeholder={t("Enter the custom subject")}
              />
            )}
            
            <div className="flex items-center justify-between mt-4">
              <span className="text-lg font-medium text-black">
                {t("Create an exercise for a lesson")}
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={generateRelatedExercise}
                  onChange={(e) => setGenerateRelatedExercise(e.target.checked)}
                />
                <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            {generateRelatedExercise && (
              <NeoBrutalistUploadField
                label={t("Upload Lesson File")}
                name="lessonFile"
                value={lessonFile}
                onChange={handleFileChange}
                onEstimate={handleEstimateLesson}
                estimate={true}
              />
            )}
            <NeoBrutalistTextArea
              label={t("Instructions")} // Updated 'Description' to 'Instructions'
              name="instructions" // Updated 'description' to 'instructions'
              error={errors.instructions} // Updated 'description' to 'instructions'
              value={exerciseData.instructions} // Updated 'description' to 'instructions'
              onChange={handleInputChange}
              placeholder={t("Enter the instructions for the exercise")}
            />
             <NeoBrutalistSelect
              label={t("Level")}
              name="level"
              value={exerciseData.level}
              error={errors.level}
              onChange={handleInputChange}
              options={[
                ...JSON.parse(process.env.REACT_APP_LEVELS).map(level => ({ value: level, label: t(level) })),
                { value: "other", label: t("Other") }
              ]}
            />
            {exerciseData.level === "other" && (
              <NeoBrutalistInput
                label={t("Custom Level")}
                name="customLevel"
                value={customLevel}
                onChange={(e) => setCustomLevel(e.target.value)}
                placeholder={t("Enter the custom level")}
              />
            )}
            
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            

            {/* <NeoBrutalistInput
            label={t('Educational Objective')}
            name="educationalObjective"
            error={errors.educationalObjective}
            value={exerciseData.educationalObjective}
            onChange={handleInputChange}
          /> */}
            <NeoBrutalistSelect
              label={t("Type")}
              name="type"
              value={exerciseData.type}
              onChange={handleInputChange}
              labelOption={false}
              options={[
                { value: "multiple_choice", label: t("Multiple Choice") },
              //  { value: "fill_in_the_blanks", label: t("Fill in the blanks") },
                { value: "free_writing", label: t("Free Writing") },
                { value: "matching", label: t("Matching") }
              ]}
            />
            {/* <NeoBrutalistSelect
              label={t("Language")}
              name="language"
              value={exerciseData.language}
              onChange={handleInputChange}
              labelOption={false}
              options={[
                { value: "english", label: t("English") },
                { value: "french", label: t("French") },
                { value: "spanish", label: t("Spanish") },
                { value: "german", label: t("German") },
                { value: "italian", label: t("Italian") },
                { value: "portuguese", label: t("Portuguese") },
                { value: "dutch", label: t("Dutch") },
                { value: "russian", label: t("Russian") },
                { value: "chinese", label: t("Chinese") },
                { value: "japanese", label: t("Japanese") },
                { value: "korean", label: t("Korean") },
              ]}
            /> */}
            <NeoBrutalistInput
              label={t("Number of Questions")}
              name="numberOfQuestions"
              type="number"
              value={exerciseData.numberOfQuestions}
              onChange={handleInputChange}
              min={1}
              max={10}
              placeholder={t("Leave empty for automatic")}
            />
            <NeoBrutalistSelect
              label={t("Tone")}
              name="tone"
              value={exerciseData.tone}
              onChange={handleInputChange}
              labelOption={false}
              options={JSON.parse(process.env.REACT_APP_TONES).map(tone => ({ value: tone, label: t(tone) }))}
            />
            {/* <div className="flex items-center mt-4">
              <input
                type="checkbox"
                id="generateLesson"
                name="generateLesson"
                checked={exerciseData.generateLesson}
                onChange={(e) =>
                  setExerciseData((prev) => ({
                    ...prev,
                    generateLesson: e.target.checked,
                  }))
                }
                className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="generateLesson"
                className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
              >
                {t("Generate a resume of the corresponding lesson")}
              </label>
            </div> */}
            <div className="flex items-center mt-4">
  <input
    type="checkbox"
    id="generateImage"
    name="generateImage"
    checked={exerciseData.generateImage}
    onChange={(e) =>
      setExerciseData((prev) => ({
        ...prev,
        generateImage: e.target.checked,
      }))
    }
    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    />
  <label
    htmlFor="generateImage"
    className="ml-2 text-lg font-medium text-black"
    >
    {t("Generate a unique illustration")} (1 <FaCoins className="ml-2 inline-block" />)
  </label>
</div>
            {/* <div className="flex items-center mt-4 mb-6">
              <input
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="acceptTerms"
                className="ml-2 text-lg font-medium text-black"
              >
                {t("I accept the")}{" "}
                <a href="/terms" target="_blank" className="text-blue-600 hover:underline">
                  {t("terms and conditions")}
                </a>
              </label>
            </div> */}

            {errors.terms && (
              <p className="text-red-500 mt-2 font-bold text-center">
                {errors.terms}
              </p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
        <div className="min-h-screen bg-gray-200 flex flex-col ">
      {/* <Header activeLink="generator" /> */}
      {/* <PageTitle>
      {t("Generate an exercise")}
     </PageTitle> */}
      <div className="flex-1 bg-gray-200 flex items-start justify-center pt-12 pb-10 px-4 sm:px-6 lg:px-8 ">
     
        <div className="max-w-lg w-full space-y-8 bg-white p-10 rounded-lg shadow-2xl border-4 border-black">
          {isLoading ? (
            <Loader
              title={step>1?t("Generating exercise..."):t("Estimating credits...")}
              description={step>1?humorousText:t("Calculation of the number of credits required for the exercise generation...")}
            />
          ) : (
            <>
              {/* <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-6 border-b-4 border-yellow-400">
                {t("Generate an exercise")}
              </h2> */}
              <form onSubmit={handleSubmit} className="mt-0 space-y-6">
                {renderStep()}
                <div className="credits-cost-block flex justify-between items-center bg-yellow-300 p-4 border-4 border-yellow-500 rounded-lg shadow-lg">
                  <h3 className="text-lg font-bold flex-1">{t("Credits Cost")}</h3>
                  <strong>{creditCost}</strong> <FaCoins className="ml-2" />
                </div>
                <div className="flex justify-between mt-8 gap-4">
                  {step > 1 ? (
                    <NeoBrutalistButton
                      onClick={handlePrevious}
                      type="button"
                      style="secondary"
                    >
                      <ChevronLeftIcon className="h-5 w-5 mr-2" />
                      <span className="hidden md:inline">
                      {t("Previous")}
                      </span>
                    </NeoBrutalistButton>
                  ) : (
                    <div className="flex-1"></div>
                  )}
                  {step < 2 ? (
                    <NeoBrutalistButton onClick={handleNext} type="button">
                      {t("Next")}
                      <ChevronRightIcon className="h-5 w-5 ml-2" />
                    </NeoBrutalistButton>
                  ) : (
                    <>
                      <NeoBrutalistButton type="submit">
                        <FaMagic className="h-5 w-5 mr-2" />
                        {t("Generate")}
                      </NeoBrutalistButton>
                    </>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <Footer/>
      </div>
      {/* <NeoBrutalistNotification
      isOpen={showNotification}
      onClose={() => setShowNotification(false)}
      message={t("You have reached your exercise generation limit. Please upgrade your plan or fill your OpenAI API key in the settings.")}
    /> */}
    {/* <OpenAIKeyModal
      isOpen={showOpenAIKeyModal}
      onClose={handleCloseOpenAIKeyModal}
      onSave={handleSaveOpenAIKey}
      freeGenerationsLeft={freeGenerationsLeft}
    /> */}
    {/* <NeoBrutalistNotification
  isOpen={showNotification}
  onClose={() => setShowNotification(false)}
  message={notificationMessage}
/> */}
    </>
  );
};

export default ExerciseGenerator;
