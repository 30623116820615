import React from 'react';

const NeoBrutalistTextArea = ({ label, name, value, onChange, error, readonly, style, placeholder }) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-lg font-bold mb-2 text-black">
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readonly}
        rows="4"
        placeholder={placeholder}
        style={style}
        className={`w-full px-4 py-2 text-black bg-white border-4 ${error ? 'border-red-500' : 'border-black'} rounded-none shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] focus:outline-none focus:ring-4 focus:ring-yellow-400 focus:border-black transition-all resize-none`}
      ></textarea>
    </div>
  );
};

export default NeoBrutalistTextArea;