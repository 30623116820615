import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";
import ExerciseGenerator from "../components/ExerciseGenerator";
import LessonGenerator from "../components/LessonGenerator";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import { FaBook, FaClipboardList } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const GeneratorsPage = ({ onCreditsUpdated }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showTabs, setShowTabs] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    if (type === "lesson") {
      setSelectedIndex(1);
    }
  }, [location]);

  const handleGenerate = () => {
    setShowTabs(false);
  };

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col">
      <div className="flex-1 bg-gray-200 flex items-start justify-center pt-12 pb-10 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl w-full space-y-8">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            {showTabs && (
            <Tab.List className="flex space-x-2 mb-4">
              <Tab as={React.Fragment}>
                {({ selected }) => (
                  <NeoBrutalistButton
                    style={selected ? "primary" : "secondary"}
                    className="w-full"
                  >
                    <FaClipboardList className="h-5 w-5 mr-2" />
                    {t("Exercise Generator")}
                  </NeoBrutalistButton>
                )}
              </Tab>
              <Tab as={React.Fragment}>
                {({ selected }) => (
                  <NeoBrutalistButton
                    style={selected ? "primary" : "secondary"}
                    className="w-full"
                  >
                    <FaBook className="h-5 w-5 mr-2" />
                    {t("Lesson Generator")}
                  </NeoBrutalistButton>
                )}
              </Tab>
            </Tab.List>
            )}
            <Tab.Panels>
              <Tab.Panel>
                <ExerciseGenerator onCreditsUpdated={onCreditsUpdated} onGenerate={handleGenerate} />
              </Tab.Panel>
              <Tab.Panel>
                <LessonGenerator onCreditsUpdated={onCreditsUpdated} onGenerate={handleGenerate} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default GeneratorsPage;