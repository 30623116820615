import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NeoBrutalistInput from "./NeoBrutalistInput";
import NeoBrutalistSelect from "./NeoBrutalistSelect";
import NeoBrutalistButton from "./NeoBrutalistButton";
import NeoBrutalistTextArea from "./NeoBrutalistTextArea";
import { axiosInstance } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { FaMagic, FaCoins } from "react-icons/fa";
import { useNotification } from '../contexts/NotificationContext';
import logger from "../utils/logger";

const LessonGenerator = ({onCreditsUpdated,onGenerate = () => {}}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [lessonData, setLessonData] = useState({
    subject: "",
    level: JSON.parse(process.env.REACT_APP_LEVELS)[0],
    instructions: "",
    tone: "",
    nbSteps: 10,
    language: i18n.language === 'fr' ? 'french' : 'english',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [creditCost, setCreditCost] = useState(1);
  const [customLevel, setCustomLevel] = useState("");
  const [step, setStep] = useState(1);

  const humorousTexts = [
    "Finding sneaky ways to trick students",
    "Automatically rewarding teacher's pets",
    "Boosting the teacher's ego",
    "Sabotaging Kevin's homework",
    "Charging the brain cells",
    "Calibrating the teacher's red pen",
    "Ensuring no one falls asleep",
    "Polishing the chalkboard",
    "Refilling the ink in virtual pens",
    "Warming up the thinking caps",
    "Making sure calculators are off",
    "Counting the number of erasers",
    "Teaching the teacher how to teach",
    "Baking cookies with the class",
    "Hiding under the teacher's desk",
    "Playing with the teacher's computer"
  ];
  const getRandomHumorousText = () => {
    return t(humorousTexts[Math.floor(Math.random() * humorousTexts.length)]);
  };
  
  const [humorousText, setHumorousText] = useState(getRandomHumorousText());

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLessonData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "subject" && value !== "other") {
      setLessonData((prevData) => ({ ...prevData, customSubject: "" }));
    }
    if (name === "level" && value !== "other") {
      setLessonData((prevData) => ({ ...prevData, customLevel: "" }));
    }
  };

  const handleSubmit = async (e) => {
   // e.preventDefault();
    
    const finalSubject = lessonData.subject === "other" ? lessonData.customSubject : lessonData.subject;
    const finalLevel = lessonData.level === "other" ? customLevel : lessonData.level;

    if(finalSubject === "") {
      setErrors({ subject: t("Subject is required") });
      return;
    }

    if(finalLevel === "") {
      setErrors({ level: t("Level is required") });
      return;
    }
    onGenerate();
    setIsLoading(true);
    try {


      console.log('finalLevel',finalLevel);

      
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/lessons/createLesson`,
        { ...lessonData, subject: finalSubject, level: finalLevel },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      navigate(`/my-lessons`);
      showNotification(t("Lesson generated successfully"));
    } catch (error) {
      console.error("Error generating lesson:", error);
      setErrors({ submit: t("An error occurred. Please try again.") });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    const newErrors = {};
    logger.log("step", step);
    if (step === 1) {
      if (!lessonData.subject.trim())
        newErrors.subject = t("Subject is required");
      /* if (!exerciseData.instructions.trim())
        newErrors.instructions = t("Instructions is required");*/
      if (!lessonData.level) newErrors.level = t("Level is required");
    }
    if (Object.keys(newErrors).length === 0) {
      if (step < 2) {
        // Vérifiez que nous ne sommes pas à la dernière étape
        setStep((prevStep) => prevStep + 1);
      }
      setErrors({});
    } else {
      setErrors(newErrors);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <NeoBrutalistSelect
              label={t("Subject")}
              name="subject"
              error={errors.subject}
              value={lessonData.subject}
              onChange={handleInputChange}
              options={[
                ...JSON.parse(process.env.REACT_APP_SUBJECTS).map(subject => ({ value: subject, label: t(subject) })),
                { value: "other", label: t("Other") }
              ]}
            />
            {lessonData.subject === "other" && (
              <NeoBrutalistInput
                label={t("Custom Subject")}
                name="customSubject"
                value={lessonData.customSubject}
                onChange={handleInputChange}
                placeholder={t("Enter the custom subject")}
              />
            )}
            <NeoBrutalistTextArea
              label={t("Instructions")}
              name="instructions"
              error={errors.instructions}
              value={lessonData.instructions}
              onChange={handleInputChange}
              placeholder={t("Enter the instructions for the exercise")}
            />
            <NeoBrutalistSelect
              label={t("Level")}
              name="level"
              value={lessonData.level}
              error={errors.level}
              onChange={handleInputChange}
              options={[
                ...JSON.parse(process.env.REACT_APP_LEVELS).map(level => ({ value: level, label: t(level) })),
                { value: "other", label: t("Other") }
              ]}
            />
            {lessonData.level === "other" && (
              <NeoBrutalistInput
                label={t("Custom Level")}
                name="customLevel"
                value={customLevel}
                onChange={(e) => setCustomLevel(e.target.value)}
                placeholder={t("Enter the custom level")}
              />
            )}
          </>
        );
      case 2:
        return (
          <>
          
          <NeoBrutalistInput
              label={t("Number of Sections")}
              name="nbSteps"
              type="number"
              value={lessonData.nbSteps}
              onChange={handleInputChange}
              min={1}
              max={20}
              placeholder={t("Enter the number of sections")}
            />
          <NeoBrutalistSelect
            label={t("Tone")}
            name="tone"
            value={lessonData.tone}
            onChange={handleInputChange}
            labelOption={false}
            options={JSON.parse(process.env.REACT_APP_TONES).map(tone => ({ value: tone, label: t(tone) }))}
          />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col">
      <div className="flex-1 bg-gray-200 flex items-start justify-center pt-12 pb-10 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg w-full space-y-8 bg-white p-10 rounded-lg shadow-2xl border-4 border-black">
          {isLoading ? (
            <Loader
              title={t("Generating lesson...")}
              description={humorousText}
            />
          ) : (
            <>
              <form  className="mt-0 space-y-6">
                {renderStep()}
                <div className="credits-cost-block flex justify-between items-center bg-yellow-300 p-4 border-4 border-yellow-500 rounded-lg shadow-lg">
                  <h3 className="text-lg font-bold flex-1">{t("Credits Cost")}</h3>
                  <strong>{creditCost}</strong> <FaCoins className="ml-2" />
                </div>
                <div className="flex justify-between mt-8 gap-4">
                  {step > 1 ? (
                    <NeoBrutalistButton
                      onClick={() => setStep(step - 1)}
                      type="button"
                      style="secondary"
                    >
                      {t("Previous")}
                    </NeoBrutalistButton>
                  ) : (
                    <div className="flex-1"></div>
                  )}
                  {step < 2 ? (
                    <NeoBrutalistButton onClick={handleNext} type="button">
                      {t("Next")}
                    </NeoBrutalistButton>
                  ) : (
                    <NeoBrutalistButton onClick={handleSubmit}>
                      <FaMagic className="h-5 w-5 mr-2" />
                      {isLoading ? t("Generating...") : t("Generate Lesson")}
                    </NeoBrutalistButton>
                  )}
                </div>
                {errors.submit && <p className="text-red-500">{errors.submit}</p>}
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonGenerator;