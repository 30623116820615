import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import NeoBrutalistCard from "../components/NeoBrutalistCard";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import logger from "../utils/logger";
import {
  FaExclamationCircle,
  FaCheckCircle,
  FaGift,
  FaExclamationTriangle,
  FaInfoCircle,
  FaCheckSquare,
  FaSquare,
  FaPlus,
  FaEye,
  FaUser,
  FaList,
  FaBook,
  FaPaperPlane,
} from "react-icons/fa";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import { useLocation } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const DashboardPage = ({ onCreditsUpdated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [pendingCorrections, setPendingCorrections] = useState([]);
  const [pendingExercises, setPendingExercises] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [recentReplies, setRecentReplies] = useState([]);
  const [notificationText, setNotificationText] = useState("");
  const [stats, setStats] = useState({
    totalExercises: 0,
    totalStudents: 0,
    exercisesSent: 0,
    totalCorrections: 0,
    notifications: [],
  });
  const [studentStats, setStudentStats] = useState([]);
const [exerciseStats, setExerciseStats] = useState([]);
const [combinedStats, setCombinedStats] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
    fetchNotifications();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const creditsPurchased = searchParams.get("creditsPurchased");
    if (creditsPurchased) {
      //   setNotificationText("Credits purchased successfully!");
      const validateNewCreditsPurchase = async (sessionId) => {
        try {
          const response = await axiosInstance.post(
            `${process.env.REACT_APP_API_URL}/subscriptions/verify-payment-credits`,
            { sessionId: creditsPurchased },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.success) {
            setNotificationText("Credits purchased successfully!");
            onCreditsUpdated(response.data.credits);
          } else {
            setNotificationText("Failed to purchase credits.");
          }
        } catch (error) {
          logger.error("Error validating subscription:", error);
          setNotificationText("Error validating subscription.");
        }
      };
      validateNewCreditsPurchase(creditsPurchased);
    }
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/notifications`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      console.log("response.data", response.data);

      const newNotifications = response.data.map((notification) => {
        notification.message = t(notification.message);
      //  if (notification.exercise) {
          if (notification.message.includes("{{title}}")) {
            notification.message = notification.message.replace(
              "{{title}}",
              notification.type === "lesson" ? notification.lesson.title : notification.exercise.title
            );
          } else {
            notification.message = notification.message.replace(
              "{{title}}",
              ""
            );
          }
          if (notification.message.includes("{{subject}}")) {
            notification.message = notification.message.replace(
              "{{subject}}",
              t(notification.exercise.subject)
            );
          } else {
            notification.message = notification.message.replace(
              "{{subject}}",
              ""
            );
          }
      //  }

        if (notification.message.includes("{{name}}")) {
          if (notification.student) {
            notification.message = notification.message.replace(
              "{{name}}",
              notification.student.name
            );
          } else {
            notification.message = notification.message.replace("{{name}}", "");
          }
        }
        return notification;
      });

      setNotifications(newNotifications);
      return response.data;
    } catch (error) {
      logger.error("Error fetching notifications:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/dashboard/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      console.log("response.data", response.data);
      setPendingCorrections(response.data.pendingCorrections);
      setPendingExercises(response.data.pendingReplies);
      setRecentReplies(response.data.recentReplies);
      setStats({
        totalExercises: response.data.totalExercises,
        totalLessons: response.data.totalLessons,
        totalStudents: response.data.totalStudents,
        lessonsSent: response.data.lessonsSent,
        exercisesSent: response.data.exercisesSent,
        totalCorrections: response.data.totalCorrections,
        notifications: response.data.notifications,
      });
      

      const combinedData = {};
      response.data.studentStats.forEach(item => {
        combinedData[item._id] = { ...combinedData[item._id], students: item.count };
      });
      response.data.exerciseStats.forEach(item => {
        combinedData[item._id] = { ...combinedData[item._id], exercises: item.count };
      });
      
      const combinedArray = Object.entries(combinedData).map(([date, data]) => ({
        date,
        students: data.students || 0,
        exercises: data.exercises || 0
      }));
      
      console.log("combinedArray", combinedArray);
      setCombinedStats(combinedArray.sort((a, b) => a.date.localeCompare(b.date)));
   

    } catch (error) {
      logger.error("Error fetching dashboard data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getIconByType = (type) => {
    switch (type) {
      case "exercise":
        return <FaCheckCircle className="text-green-500" />;
      case "error":
        return <FaExclamationCircle className="text-red-500" />;
      case "gift":
        return <FaGift className="text-yellow-500" />;
      case "warning":
        return <FaExclamationTriangle className="text-orange-500" />;
      default:
        return <FaInfoCircle className="text-blue-500" />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader
          title={t("Loading Dashboard")}
          description={t("Please wait while we load your dashboard data.")}
        />
      </div>
    );
  }

  const handleMarkAsRead = async (notificationId) => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/notifications/${notificationId}/read`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // Mettre à jour l'état local des notifications
      setNotifications((prevNotifications) =>
        prevNotifications.map((note) =>
          note._id === notificationId ? { ...note, read: true, fading: true } : note
        )
      );
      // Retirer la notification après le fadeout
      setTimeout(() => {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((note) => note._id !== notificationId)
        );
      }, 500); // Correspond à la durée de la transition CSS
    } catch (error) {
      logger.error(
        "Erreur lors du marquage de la notification comme lue:",
        error
      );
    }
  };
  

  return (
    <>
      {/* <Header activeLink="dashboard" /> */}
      <div className="min-h-screen bg-gray-100 px-4 py-8">
        <div className="container mx-auto px-4 py-0">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 md:gap-16">
            
          <div className="lg:col-span-4 space-y-8 mb-12 mf:mb-0">
              <NeoBrutalistCard
                title={t("Pending Corrections")}
                interactable={false}
              >
                <div className="flex flex-row space-x-4 align-center items-center  px-8">
                  <p className="text-lg md:text-4xl font-bold text-center">
                    {pendingCorrections.length}
                  </p>
                  <p className="text-start md:text-center text-sm">
                    {t("exercises pending correction")}
                  </p>
                </div>
                <div className="flex flex-row space-x-4 align-center items-center  px-8">
                  <p className="text-lg md:text-4xl font-bold text-center">
                    {pendingExercises.length}
                  </p>
                  <p className="text-start md:text-center text-sm">
                    {t("pending student's replies")}
                  </p>
                </div>
                <NeoBrutalistButton
                  onClick={() => navigate("/my-exercises")} 
                  style="info"
                  className="mt-4 w-full"
                >
                  <FaList className="mr-2" />
                  {t("View Exercises")}
                </NeoBrutalistButton>
              </NeoBrutalistCard>
              <NeoBrutalistCard title={t("Quick Actions")} interactable={false}>
                <div className="flex flex-col space-y-4 ">
                  <NeoBrutalistButton
                    onClick={() => navigate("/generator")}
                    style="primary"
                  >
                    <FaPlus className="mr-2" />
                    {t("Create New Exercise")}
                  </NeoBrutalistButton>
                  <NeoBrutalistButton
                    onClick={() => navigate("/my-exercises")}
                    style="secondary"
                  >
                    <FaEye className="mr-2" />
                    {t("View My Exercises")}
                  </NeoBrutalistButton>
                  <NeoBrutalistButton
                    onClick={() => navigate("/my-students")}
                    style="secondary"
                  >
                    <FaUser className="mr-2" />
                    {t("Manage My Students")}
                  </NeoBrutalistButton>
                </div>
              </NeoBrutalistCard>
              <NeoBrutalistCard
                title={t("Your Statistics")}
                interactable={false}
              >
                <div className="space-y-2">
                  <p className="text-sm md:text-lg flex flex-row space-x-2">
                    <FaBook className="mr-2 text-blue-500 mt-1" />
                    {t("Total Exercises Created")}:{" "}
                    <span className="font-bold">{stats.totalExercises}</span>
                  </p>
                  <p className="text-sm md:text-lg flex flex-row space-x-2">
                    <FaBook className="mr-2 text-blue-500 mt-1" />
                    {t("Total Lessons Created")}:{" "}
                    <span className="font-bold">{stats.totalLessons}</span>
                  </p>
                  <p className="text-sm md:text-lg flex flex-row space-x-2">
                    <FaUser className="mr-2 text-blue-500 mt-1" />
                    {t("Total Students")}:{" "}
                    <span className="font-bold">{stats.totalStudents}</span>
                  </p>
                  <p className="text-sm md:text-lg flex flex-row space-x-2">
                    <FaPaperPlane className="mr-2 text-blue-500 mt-1" />
                    {t("Exercises Sent")}:{" "}
                    <span className="font-bold">{stats.exercisesSent}</span>
                  </p>
                  <p className="text-sm md:text-lg flex flex-row space-x-2">
                    <FaPaperPlane className="mr-2 text-blue-500 mt-1" />
                    {t("Lessons Sent")}:{" "}
                    <span className="font-bold">{stats.lessonsSent}</span>
                  </p>
                  <p className="text-sm md:text-lg flex flex-row space-x-2">
                    <FaCheckSquare className="mr-2 text-blue-500 mt-1" />
                    {t("Total Corrections Made")}:{" "}
                    <span className="font-bold">{stats.totalCorrections}</span>
                  </p>
                </div>
              </NeoBrutalistCard>
            </div>
            
            <div className="lg:col-span-8 space-y-8">
              <div title={t("Notifications")}>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">
                    {t("Notifications")}
                  </h2>
                  {notifications.length > 0 && (
                    <button
                      onClick={async () => {
                        try {
                          await axiosInstance.put(
                            `${process.env.REACT_APP_API_URL}/notifications/mark-all-read`,
                            {},
                            {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                  "token"
                                )}`,
                              },
                            }
                          );
                          setNotifications((prevNotifications) =>
                            prevNotifications.map((note) => ({
                              ...note,
                              read: true,
                            }))
                          );
                        } catch (error) {
                          logger.error(
                            "Erreur lors du marquage de toutes les notifications comme lues:",
                            error
                          );
                        }
                      }}
                      className="hidden md:flex bg-yellow-400 text-black px-4 py-2 rounded flex items-center hover:bg-yellow-500 "
                    >
                      <FaCheckSquare className="mr-2" />
                      {t("Mark all as read")}
                    </button>
                  )}
                </div>
                {notifications.length === 0 ? (
                  <p>{t("No new notifications")}</p>
                ) : (
                  notifications.map((note, index) => (
                    <li
                      key={index}
                      className={`p-4 border-4 border-black mb-4 flex items-center space-x-4 bg-white shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]  ${
                        note.read ? "opacity-50" : ""
                      }`}
                    >
                      <div className="hidden sm:block flex-shrink-0 text-4xl mr-4 flex items-center justify-center h-100%">
                        {getIconByType(note.type)}
                      </div>
                      <div className="flex-grow">
                        <p className="font-bold text-sm md:text-lg">{t(note.message)}</p>
                        <p className="text-sm text-gray-500">
                          {new Date(note.createdAt).toLocaleString()}
                        </p>
                        {note.exercise && (
                          <p className="text-sm">
                            <strong>{t("Exercise")}:</strong>{" "}
                            {note.exercise.title} ({t(note.exercise.subject)})
                          </p>
                        )}
                        {note.student && (
                          <p className="text-sm">
                            <strong>{t("Student")}:</strong>{" "}
                            {note.student.firstName} ({note.student.email})
                          </p>
                        )}
                      </div>
                      <div className="flex-shrink-0 text-2xl">
                        <button onClick={() => handleMarkAsRead(note._id)}>
                          {note.read ? (
                            <FaCheckSquare className="text-green-500" />
                          ) : (
                            <FaSquare className="text-gray-300" />
                          )}
                        </button>
                      </div>
                    </li>
                  ))
                )}
              </div>
              <div className="hidden md:block lg:col-span-8 space-y-8">
              <h2 className="text-xl font-semibold">
                    {t("Monthly Growth")}
                  </h2>
                          <NeoBrutalistCard title={t("Monthly Growth")} interactable={false}>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={combinedStats}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="date" 
                      tickFormatter={(date) => new Date(date).toLocaleString('default', { month: 'long' })} 
                    />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="students" stroke="#8884d8" name={t("Number of Students")} />
                    <Line yAxisId="right" type="monotone" dataKey="exercises" stroke="#82ca9d" name={t("Number of Exercises")} />
                  </LineChart>
                </ResponsiveContainer>
              </NeoBrutalistCard>
</div>
            </div>
            
            
          </div>
        </div>
      </div>
      <Footer />
      {notificationText && (
        <NeoBrutalistNotification message={notificationText} type="success" />
      )}
    </>
  );
};

export default DashboardPage;
