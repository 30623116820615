import React, { useRef, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const AvatarSelector = ({ selectedAvatar, onSelectAvatar, teachers=false }) => {
  const { t } = useTranslation();
  const avatars = Array.from({ length: 20 }, (_, i) => 
    teachers ? `teachers/teacher_${i + 1}.png` : `avatar${i + 1}.png`
  );
    const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container && selectedAvatar) {
      const selectedIndex = avatars.indexOf(selectedAvatar);
      if (selectedIndex !== -1) {
        const selectedButton = container.children[selectedIndex];
        if (selectedButton) {
          const offsetLeft = selectedButton.offsetLeft;
          const offsetWidth = selectedButton.offsetWidth;
          const containerWidth = container.clientWidth;
          const scrollPosition = offsetLeft - (containerWidth / 2) + (offsetWidth / 2);
          container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        }
      }
    }
  }, [selectedAvatar, avatars]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -128 : 128;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  return (
    <div className="mt-4">
      <h3 className="text-xl font-bold mb-2">{t('Select Avatar')}</h3>
      <div className="relative">
      {showLeftArrow ? (
  <button
  type='button'
    onClick={() => scroll('left')}
    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-yellow-400 border-4 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
  >
    <ChevronLeftIcon className="w-6 h-6" />
  </button>
) : (
  <button
    disabled
    type='button'
    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-400 border-4 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] cursor-not-allowed"
  >
    <ChevronLeftIcon className="w-6 h-6" />
  </button>
)}
        <div
          ref={scrollContainerRef}
          className="flex overflow-x-auto scrollbar-hide space-x-4 py-2 px-2 mx-14 border-4 border-gray-300"
          onScroll={handleScroll}
        >
          {avatars.map((avatar, index) => (
            <button
              key={index}
              onClick={() => onSelectAvatar(avatar)}
              className={`flex-shrink-0 p-1 rounded-lg transition-all duration-200 ${
                selectedAvatar === avatar
                  ? 'bg-yellow-400 border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]'
                  : 'bg-white border-4 border-gray-300 hover:border-black  shadow-[4px_4px_0px_0px_rgba(0,0,0,0)]'
              }`}
            >
              <img src={"/img/avatars/"+avatar} alt={`Avatar ${index + 1}`} className="w-24 h-24" />
            </button>
          ))}
        </div>
        {showRightArrow ? (
  <button
  type='button'
    onClick={() => scroll('right')}
    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-yellow-400 border-4 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
  >
    <ChevronRightIcon className="w-6 h-6" />
  </button>
) : (
  <button
    disabled
    type='button'
    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-400 border-4 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] cursor-not-allowed"
  >
    <ChevronRightIcon className="w-6 h-6" />
  </button>
)}
      </div>
    </div>
  );
};

export default AvatarSelector;