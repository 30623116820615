import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import NeoBrutalistSelect from "../components/NeoBrutalistSelect";
import NeoBrutalistInput from "../components/NeoBrutalistInput";
import NeoBrutalistModal from "../components/NeoBrutalistModal";
import {
  PencilIcon,
  TrashIcon,
  DownloadIcon,
  PaperAirplaneIcon,
  ClipboardCheckIcon,
  PlusIcon,
  FolderIcon,
  EyeIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { axiosInstance } from "../axiosConfig";
import Footer from "../components/Footer";
import logger from "../utils/logger";
import ViewGridIcon from "../components/ViewGridIcon";
import ViewListIcon from "../components/ViewListIcon";
import { FaRocket } from "react-icons/fa";
import StudentLessonPage from "./StudentLessonPage";
import LessonEditor from "../components/LessonEditor";
import SendLessonModal from "../components/SendLessonModal";
import DroppableFolder from "../components/DroppableFolder";

const MyLessonsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [lessons, setLessons] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingLesson, setEditingLesson] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteButtonRef = useRef(null);
  const [viewMode, setViewMode] = useState("grid");
  const [filters, setFilters] = useState({ subject: "", level: "" });
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [activeFolder, setActiveFolder] = useState(null);
  const [previewLesson, setPreviewLesson] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);

  useEffect(() => {
    fetchLessons();
    fetchFolders();
  }, [filters, currentFolder]);

  const handlePreview = (lesson) => {
    setPreviewLesson(lesson);
    setIsPreviewModalOpen(true);
  };
  const handleSend = (lesson) => {
    setSelectedLesson(lesson);
    setSendModalOpen(true);
  };
  const handleLessonSent = (lessonId, sentToStudents) => {
    // Mettre à jour l'état local si nécessaire
    logger.log(`Lesson ${lessonId} sent to ${sentToStudents.length} students`);
    // Vous pouvez également rafraîchir la liste des leçons ici si nécessaire
    fetchLessons();
  };

  const fetchLessons = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/lessons/my-lessons`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { ...filters, folderId: currentFolder },
        }
      );
      setLessons(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des leçons:", error);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/folders?type=lesson`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setFolders(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: value }));
  };

  const handleEdit = (lesson) => {
    setEditingLesson(lesson);
    setIsEditing(true);
  };

  const handleSaveEdit = async (editedLesson) => {
    try {
      const exerciseElement = document.getElementById(
        `lesson-${editedLesson._id}`
      );
      if (exerciseElement) {
        exerciseElement.classList.add("bounce-animation");
        setTimeout(() => {
          exerciseElement.classList.remove("bounce-animation");
        }, 3000); // Remove the class after 3 seconds
      }

      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/lessons/${editedLesson._id}`,
        editedLesson,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setIsEditing(false);
      setEditingLesson(null);
      fetchLessons();
    } catch (error) {
      logger.error("Erreur lors de la mise à jour de la leçon:", error);
    }
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    setEditingLesson(null);
  };

  const handleDelete = (id) => {
    setDeleteConfirmation(id);
  };

  const confirmDelete = async () => {
    if (deleteConfirmation) {
      try {
        await axiosInstance.delete(
          `${process.env.REACT_APP_API_URL}/lessons/${deleteConfirmation}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        fetchLessons();
        setDeleteConfirmation(null);
      } catch (error) {
        logger.error("Erreur lors de la suppression de la leçon:", error);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const handleDownload = async (lesson) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/lessons/${lesson._id}/download`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${lesson.title}.pdf`;
      link.click();
    } catch (error) {
      logger.error("Erreur lors du téléchargement du PDF:", error);
    }
  };

  const handleAddFolder = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/folders`,
        { name: newFolderName, type: "lesson" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setFolders([...folders, response.data]);
      setIsAddFolderModalOpen(false);
      setNewFolderName("");
    } catch (error) {
      logger.error("Erreur lors de la création du dossier:", error);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/folders/${folderId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setFolders(folders.filter((folder) => folder._id !== folderId));
    } catch (error) {
      logger.error("Erreur lors de la suppression du dossier:", error);
    }
  };

  const handleMoveLesson = async (lessonId, folderId) => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/lessons/${lessonId}/move`,
        { folderId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchLessons();
    } catch (error) {
      logger.error("Erreur lors du déplacement de la leçon:", error);
    }
  };

  const handleFolderClick = (folderId) => {
    if (activeFolder === folderId) {
      setActiveFolder(null);
      setCurrentFolder(null);
    } else {
      setActiveFolder(folderId);
      setCurrentFolder(folderId);
    }
  };

  return (
    <>
      <div className="min-h-screen bg-gray-200 px-4 py-8">
        <div className="container mx-auto px-4 pt-0 pb-8">
          <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <div className="flex flex-wrap gap-2 w-full sm:w-auto">
              <NeoBrutalistSelect
                labelOption={false}
                options={[
                  { value: "", label: t("All Subjects") },
                  ...JSON.parse(process.env.REACT_APP_SUBJECTS).map(
                    (subject) => ({ value: subject, label: t(subject) })
                  ),
                  { value: "other", label: t("Other") },
                ]}
                value={filters.subject}
                onChange={(e) => handleFilterChange("subject", e.target.value)}
                className="w-full sm:w-auto"
              />
              <NeoBrutalistSelect
                labelOption={false}
                options={[
                  { value: "", label: t("All Levels") },
                  ...JSON.parse(process.env.REACT_APP_LEVELS).map((level) => ({
                    value: level,
                    label: t(level),
                  })),
                  { value: "other", label: t("Other") },
                ]}
                value={filters.level}
                onChange={(e) => handleFilterChange("level", e.target.value)}
                className="w-full sm:w-auto"
              />
              {/* <NeoBrutalistButton
                size="small"
                style="secondary"
                onClick={() => setIsAddFolderModalOpen(true)}
                className="w-full sm:w-auto sm:my-3 sm:relative sm:-top-1"
              >
                <PlusIcon className="h-4 w-4 inline mr-2" />
                {t("Add Folder")}
              </NeoBrutalistButton>
               */}
              <NeoBrutalistButton
                onClick={() => navigate("/generators?type=lesson")}
                size="small"
                className="w-full sm:w-auto sm:my-3 sm:relative sm:-top-1"
              >
                <FaRocket className="w-4 h-4 mr-2" />
                {t("Create lesson")}
              </NeoBrutalistButton>
            </div>
            <div className="hidden md:flex flex space-x-2 w-full sm:w-auto justify-center sm:justify-start">
              <NeoBrutalistButton
                onClick={() => setViewMode("grid")}
                style={viewMode === "grid" ? "primary" : "secondary"}
              >
                <ViewGridIcon className="h-5 w-5" />
              </NeoBrutalistButton>
              <NeoBrutalistButton
                onClick={() => setViewMode("list")}
                style={viewMode === "list" ? "primary" : "secondary"}
              >
                <ViewListIcon className="h-5 w-5" />
              </NeoBrutalistButton>
            </div>
          </div>

          {folders.length > -1 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4">{t("Folders")}</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {folders.map((folder) => (
                  <DroppableFolder
                  key={folder._id}
                  folder={folder}
                  // onDrop={handleMoveExercise}
                  onDelete={handleDeleteFolder}
                >
                     <div
                      onClick={() => handleFolderClick(folder._id)}
                      className={`cursor-pointer transition-all duration-200 ${
                        activeFolder === folder._id
                          ? "bg-yellow-200 border-yellow-400"
                          : "bg-white hover:bg-gray-100 border-gray-300"
                      } border-2 rounded-lg p-4 flex flex-col items-center shadow-md`}
                    >
                      <svg
                        className={`w-12 h-12 mb-2 ${
                          activeFolder === folder._id
                            ? "text-yellow-600"
                            : "text-gray-500"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                      </svg>
                      <span
                        className={`text-sm font-medium text-center ${
                          activeFolder === folder._id
                            ? "text-yellow-800"
                            : "text-gray-700"
                        }`}
                      >
                        {folder.name}
                      </span>
                    </div>
                  </DroppableFolder>
                ))}
                <div
                  onClick={() => setIsAddFolderModalOpen(true)}
                  className="cursor-pointer bg-gray-100 hover:bg-gray-200 border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center"
                >
                  <PlusIcon className="w-8 h-8 mb-2 text-gray-500" />
                  <span className="text-sm font-medium text-gray-500">
                    {t("Add Folder")}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              viewMode === "grid"
                ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 md:mt-14"
                : "space-y-4 md:mt-14"
            }
          >
            {lessons.map((lesson) => (
              <div
                key={lesson._id}
                id={`lesson-${lesson._id}`}
                className={`bg-white border-4 border-black rounded-none shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transition-all duration-300 relative ${
                  viewMode === "list"
                    ? "p-4 flex items-center space-x-4"
                    : "p-6"
                }`}
              >
                {viewMode === "list" ? (
                  <>
                    <div className="flex-shrink-0 w-24 h-24 relative" i>
                      <img
                        src={
                          lesson.illustration ||
                          process.env.REACT_APP_URL +
                            "/img/default_illustration.webp"
                        }
                        alt={lesson.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute -top-2 -right-2 transform rotate-3 z-1">
                        <div className="bg-blue-500 text-white border-2 border-black px-2 py-1 text-sm font-bold shadow-[2px_2px_0px_0px_rgba(0,0,0,1)]">
                          {t(lesson.subject)}
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow">
                      <h2 className="text-xl font-bold text-black truncate">
                        {lesson.title}
                      </h2>
                      <p className="text-gray-600 text-sm mb-2 line-clamp-2">
                        {lesson.description}
                      </p>
                      <div className="flex items-center space-x-2 text-sm">
                        <span className="font-semibold">{t("Level")}:</span>
                        <span>{t(lesson.level)}</span>
                      </div>
                    </div>
                    <div className="flex-shrink-0 flex space-x-2">
                      <NeoBrutalistButton
                        onClick={() => handleEdit(lesson)}
                        style="secondary"
                        size="small"
                        tooltip={t("Edit")}
                        containerClassName="z-10"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </NeoBrutalistButton>
                      <NeoBrutalistButton
                        onClick={() => handlePreview(lesson)}
                        style="secondary"
                        size="small"
                        containerClassName="z-10"
                        tooltip={t("Preview")}
                      >
                        <EyeIcon className="h-4 w-4" />
                      </NeoBrutalistButton>
                      <NeoBrutalistButton
                        onClick={() => handleDownload(lesson)}
                        style="secondary"
                        size="small"
                        containerClassName="z-10"
                        tooltip={t("Download")}
                      >
                        <DownloadIcon className="h-4 w-4" />
                      </NeoBrutalistButton>
                      <NeoBrutalistButton
                        onClick={() => handleSend(lesson)}
                        style="primary"
                        size="small"
                        containerClassName="z-10"
                        tooltip={t("Send")}
                      >
                        <PaperAirplaneIcon className="h-4 w-4" />
                      </NeoBrutalistButton>
                    </div>
                    <div
                      className="absolute -top-2 -right-3 bg-white border-2 border-black rounded-full p-2 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:shadow-[1px_1px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer z-1"
                      onClick={() => handleDelete(lesson._id)}
                    >
                      <TrashIcon className="h-4 w-4 text-red-500" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="absolute -top-3 -right-3 transform rotate-3 z-1">
                      <div className="bg-blue-500 text-white border-2 border-black px-2 py-2 text-lg font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                        {t(lesson.subject)}
                      </div>
                    </div>
                    <div
                      className="absolute top-2 left-2 bg-white border-2 border-black rounded-full p-1 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer"
                      onClick={() => handleDelete(lesson._id)}
                    >
                      <TrashIcon className="h-6 w-6 text-red-500" />
                    </div>
                    <img
                      src={
                        lesson.illustration ||
                        process.env.REACT_APP_URL +
                          "/img/default_illustration.webp"
                      }
                      alt={lesson.title}
                      className="w-full h-32 object-cover mb-4"
                    />
                    <h2 className="text-xl font-bold mb-4 text-black h-8 overflow-hidden">
                      {lesson.title}
                    </h2>
                    <p className="text-gray-700 mb-4 md:h-24 overflow-y-auto text-sm">
                      {lesson.description}
                    </p>
                    <div className="bg-gray-200 border-2 border-gray-400 p-3 rounded-md mb-4 shadow-md text-sm">
                      <div>
                        <span className="font-bold text-gray-800">
                          {t("Level")}:{" "}
                        </span>
                        <span className="text-gray-600">{t(lesson.level)}</span>
                      </div>
                    </div>
                    {folders.length > 0 && (
                      <NeoBrutalistSelect
                        icon={<FolderIcon className="h-6 w-6" />}
                        labelOption={false}
                        size="small"
                        value={lesson.folder}
                        options={[
                          { value: "", label: t("Move to Folder") },
                          ...folders.map((folder) => ({
                            value: folder._id,
                            label: folder.name,
                          })),
                        ]}
                        onChange={(e) =>
                          handleMoveLesson(lesson._id, e.target.value)
                        }
                      />
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2">
                      <NeoBrutalistButton
                        onClick={() => handleEdit(lesson)}
                        style="secondary"
                        size="small"
                        className="flex-1"
                      >
                        <PencilIcon className="h-6 w-6 mr-2" />
                        {t("Edit")}
                      </NeoBrutalistButton>
                      <NeoBrutalistButton
                        onClick={() => handlePreview(lesson)}
                        style="secondary"
                        size="small"
                        className="flex-1"
                      >
                        <EyeIcon className="h-6 w-6 mr-2" />
                        {t("Preview")}
                      </NeoBrutalistButton>
                      <NeoBrutalistButton
                        onClick={() => handleDownload(lesson)}
                        style="secondary"
                        size="small"
                        className="flex-1"
                      >
                        <DownloadIcon className="h-6 w-6 mr-2" />
                        {t("Download")}
                      </NeoBrutalistButton>
                      <NeoBrutalistButton
                        onClick={() => handleSend(lesson)}
                        style="primary"
                        size="small"
                        className="flex-1"
                      >
                        <PaperAirplaneIcon className="h-6 w-6 mr-2" />
                        {t("Send")}
                      </NeoBrutalistButton>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        {deleteConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
              <h2 className="text-2xl font-bold mb-4">
                {t("Confirm Deletion")}
              </h2>
              <p className="mb-4">
                {t("Are you sure you want to delete this lesson?")}
              </p>
              <div className="flex justify-end space-x-4">
                <NeoBrutalistButton onClick={cancelDelete} style="secondary">
                  {t("Cancel")}
                </NeoBrutalistButton>
                <NeoBrutalistButton
                  onClick={confirmDelete}
                  style="error"
                  ref={deleteButtonRef}
                >
                  {t("Delete")}
                </NeoBrutalistButton>
              </div>
            </div>
          </div>
        )}
        <NeoBrutalistModal
          isOpen={isAddFolderModalOpen}
          onClose={() => setIsAddFolderModalOpen(false)}
          title={t("Add New Folder")}
        >
          <NeoBrutalistInput
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder={t("Folder Name")}
          />

          <div className="flex justify-end mt-4">
            <NeoBrutalistButton onClick={handleAddFolder}>
              <CheckIcon className="w-4 h-4 mr-2" />
              {t("Create Folder")}
            </NeoBrutalistButton>
          </div>
        </NeoBrutalistModal>

        {isEditing && editingLesson && (
          <LessonEditor
            lesson={editingLesson}
            onSave={handleSaveEdit}
            onClose={handleCloseEdit}
          />
        )}

        {isPreviewModalOpen && (
          <StudentLessonPage
            loadedLesson={previewLesson}
            isPreview={true}
            onClosePreview={() => setIsPreviewModalOpen(false)}
          />
        )}
         {sendModalOpen && selectedLesson && (
        <SendLessonModal
          isOpen={sendModalOpen}
          onClose={() => setSendModalOpen(false)}
          lesson={selectedLesson}
          onLessonSent={handleLessonSent}
        />
      )}
      </div>
      <Footer />
    </>
  );
};

export default MyLessonsPage;
